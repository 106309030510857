import { useState, useRef } from 'react';

// material-ui
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// project import
import { IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// assets
import { ManageSearch, Search } from '@mui/icons-material/';
import { DiContainer, Module, NodeData } from '../rete/types';
import { slugify_text, getIconNode } from '../util';
import { zoomAtNode, openModule } from '../util/EditorUtils';
// ==============================|| HEADER CONTENT - SEARCH NODE ||============================== //

declare type SearchNodesProps = {
    modules: Module[];
    setSelectedModule: (module: string) => void;
    di?: DiContainer;
};

export const SearchNode = (props: SearchNodesProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [renderNodes, setRenderNodes] = useState<NodeData[]>([]);

    // Agregar ref para el TextField
    const inputRef = useRef<HTMLInputElement>(null);

    const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setRenderNodes([]);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        const nodes = props.modules.flatMap(module => module.content);

        // Filter the nodes that match the search term
        const filteredNodes = nodes.filter(node => slugify_text(node.name).includes(slugify_text(value)));

        // Update the state with the filtered nodes
        setRenderNodes(filteredNodes);
    };

    const handleNodeClick = (node: NodeData) => {
        handleClose();

        const targetModuleObj = props.modules.find((module) => module.label === node.module);
        if (targetModuleObj && node.module) {
            props.setSelectedModule(node.module);
            openModule(targetModuleObj, props.di!);
        }

        setTimeout(() => zoomAtNode(node.id, props.di!), 1000);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <Tooltip title={t('Search Node')}>
                <IconButton
                    size='small'
                    id='search_node'
                    aria-label="Search Node"
                    onClick={handleToggle}
                    sx={{ ml: 1 }}
                    color='primary'
                >
                    <ManageSearch />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        padding: 2,
                    },
                }}
                TransitionProps={{
                    onEntered: () => {
                        inputRef.current?.focus();
                    },
                }}
            >
                <TextField
                    inputRef={inputRef}
                    id="input-with-icon-textfield"
                    label={t('Search Node')}
                    placeholder={t('Search Node')}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    onChange={handleChange}
                    autoComplete='off'
                />
                {renderNodes.map((node) => (
                    <Box
                        key={node.id}
                        className='selected-node'
                        onClick={() => handleNodeClick(node)}
                        sx={{ verticalAlign: 'middle', display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                        {getIconNode(node.type, node.name)}
                        <Typography variant='body1' sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                            {node.name}
                        </Typography>
                    </Box>
                ))}
            </Popover>
        </Box>
    );
};