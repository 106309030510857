import { ClassicPreset as Classic } from 'rete';
import { InputControl } from '../controls';
import { GotoData } from '../models/Goto';
import { DiContainer } from '../types';
import { GotoModalControl, GoSectionButtonControl } from '../controls/';

const socket = new Classic.Socket('socket');

declare type GotoNodeData = {
    node_id: string;
    goto_data?: GotoData;
};

export class GotoNode extends Classic.Node {
    width = 220;
    height = 120;
    constructor(di: DiContainer, nodeData?: GotoNodeData) {
        super('Goto');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addControl(
            'node_id',
            new InputControl({ hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id })
        );
        this.addControl(
            'modal',
            new GotoModalControl(
                { di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket, modalInfo: nodeData?.goto_data }
            )
        );

        if (nodeData?.goto_data) {
            this.addControl('section_button', new GoSectionButtonControl({di: di, nodeData: nodeData.goto_data}));
        }
    }
}