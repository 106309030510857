import React from 'react';
import config from '../config.json';
import { t } from 'i18next';
import {
    Textsms,
    Input,
    ListAlt,
    Bookmark,
    Mms,
    AppRegistration,
    Outbound,
    AccessTime,
    PersonAdd,
    PersonRemove,
    LocationOn,
    MoreTime,
    Schema,
    Http,
    Email,
    SmartToy,
    SmartButton,
    RadioButtonChecked,
    MoveUp,
    BackupTable,
    Feed,
    PlayCircleFilled,
} from '@mui/icons-material';

const slugify = require('slugify');

const chatbotRegex = new RegExp(config.regex.chatbot_regex);

export const mainParams = (): [string, string] => {
    const path = window.location.pathname;
    const params = path.split('/');

    if (params.length < 3) {
        throw new Error('Invalid URL format, /<service>/@<bot_mxid>:<domain> expected');
    }

    const service = params[1];
    const bot_mxid = params[2];
    if (!chatbotRegex.test(bot_mxid)) {
        throw new Error('Invalid bot_mxid format, @<bot_mxid>:<domain> expected');
    }

    return [service, bot_mxid];
};

export const checkJinja2Syntaxis = (value: string): boolean => {
    const jinja2Regex = new RegExp(config.regex.jinja2_variable);
    return jinja2Regex.test(value);
};

export const clearMessage = (text: string | undefined): string => {
    if (text === undefined || text === '' || text === null) {
        return '';
    }
    return text.replace(/\\s/g, '');
};

export const getFormatTextAddEmoji = (text: string, position: number, element: string) => {
    const subTextValueStart = text.substring(0, position);
    const subTextValueEnd = text.substring(position, text.length).trim();
    const formattedText = `${subTextValueStart}${element} ${subTextValueEnd}`;
    return formattedText.trim();
};

export const checkValidUrl = (url: string): boolean => {
    const urlRegex = new RegExp('^(https|http):\/\/[^\s/$.?#].[^\s]*$');
    return urlRegex.test(url);
};

export const slugify_text = (text?: string): string => {
    if (text === undefined) return '';
    return slugify(text, {
        lower: true,
        remove: /[*+~.()'"!:@]/g,
        locale: config.language,
        replacement: '_',
    });
};

export const formatDate = (date: Date): string => {
    const timezone = config.default_timezone;
    const dateObject = new Date(date);

    const year = dateObject.toLocaleString('es-ES', { year: 'numeric', timeZone: timezone });
    const month = dateObject.toLocaleString('es-ES', { month: '2-digit', timeZone: timezone });
    const day = dateObject.toLocaleString('es-ES', { day: '2-digit', timeZone: timezone });
    const hour = dateObject.toLocaleString('es-ES', { hour: '2-digit', hour12: false, timeZone: timezone });
    const minute = dateObject.toLocaleString('es-ES', { minute: '2-digit', timeZone: timezone }).padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minute}`;
};

/**
 * Format a date to display:
 * - "Today at HH:MM" for today's dates
 * - "Yesterday" for yesterday's date
 * - null for any other date
 * considering the configured timezone
 * @param {Date} date - Date to be formatted
 * @returns {string | null} - Date formatted or null if not today's or yesterday's date
 */
export const formatDateToHumanReadable = (date: Date): string | null => {
    const timezone = config.default_timezone;
    const dateToFormat = new Date(date);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Compare dates ignoring times
    const dateStr = dateToFormat.toLocaleString('es-ES', { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit' });
    const todayStr = today.toLocaleString('es-ES', { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit' });
    const yesterdayStr = yesterday.toLocaleString('es-ES', { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit' });

    if (dateStr === yesterdayStr) {
        return t('utils.yesterday');
    } else if (dateStr === todayStr) {
        const timeStr = dateToFormat.toLocaleString('es-ES', {
            timeZone: timezone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
        return `${t('utils.today_at')}: ${timeStr}`;
    }

    //if the date is not today or yesterday, return 'month day, year'
    return dateToFormat.toLocaleString('es-ES', {
        timeZone: timezone,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

export const getIconNode = (typeNode: string, name:string) => {
    const nodeName = name === 'start' ? 'start' : typeNode;

    switch (nodeName) {
    case 'start':
        return React.createElement(PlayCircleFilled, { fontSize: 'medium' });
    case 'message':
        return React.createElement(Textsms, { fontSize: 'medium' });
    case 'input':
        return React.createElement(Input, { fontSize: 'medium' });
    case 'switch':
        return React.createElement(ListAlt, { fontSize: 'medium' });
    case 'media':
        return React.createElement(Mms, { fontSize: 'medium' });
    case 'set_vars':
        return React.createElement(AppRegistration, { fontSize: 'medium' });
    case 'subroutine':
        return React.createElement(Outbound, { fontSize: 'medium' });
    case 'check_time':
        return React.createElement(AccessTime, { fontSize: 'medium' });
    case 'invite_user':
        return React.createElement(PersonAdd, { fontSize: 'medium' });
    case 'leave':
        return React.createElement(PersonRemove, { fontSize: 'medium' });
    case 'location':
        return React.createElement(LocationOn, { fontSize: 'medium' });
    case 'delay':
        return React.createElement(MoreTime, { fontSize: 'medium' });
    case 'distribute_chat':
        return React.createElement(Schema, { fontSize: 'medium' });
    case 'http_request':
        return React.createElement(Http, { fontSize: 'medium' });
    case 'email':
        return React.createElement(Email, { fontSize: 'medium' });
    case 'gpt_assistant':
        return React.createElement(SmartToy, { fontSize: 'medium' });
    case 'interactive':
        return React.createElement(SmartButton, { fontSize: 'medium' });
    case 'start_sub':
        return React.createElement(RadioButtonChecked, { fontSize: 'medium' });
    case 'go_to':
        return React.createElement(MoveUp, { fontSize: 'medium' });
    case 'start_section':
        return React.createElement(BackupTable, { fontSize: 'medium' });
    case 'form':
        return React.createElement(Feed, { fontSize: 'medium' });
    default:
        return React.createElement(Bookmark, { fontSize: 'medium' });
    }
};
