import React, { useState } from 'react';
import { Dehaze, Remove } from '@mui/icons-material';
import { AccordionDetails, Box, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { getFormatTextAddEmoji } from '../../../util/util';
import EmojiPickerComponent, { alignButton } from '../EmojiPcker';

export const FormVariablesComponent = (
    props: {
        index: number;
        variable_type: 'body' | 'header' | 'button';
        textFileWidth: string;
        variable: string;
        setVariables: (variables: (prev: string[]) => string[]) => void;
        variables: string[];
    }
): JSX.Element => {
    const [messageInput, setMessageInput] = useState<HTMLInputElement | null>(null);

    const getMessageInput = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInput(input);
        }
    };

    const handleChangeOption = (value: string) => {
        const newVariables = [...props.variables];
        newVariables[props.index] = value;
        props.setVariables(() => newVariables);
    };

    const removeVariable = (index: number) => {
        const newVariables = [...props.variables];
        newVariables.splice(index, 1);
        props.setVariables(() => newVariables);
    };

    const addEmoji = (emoji: string): void => {
        //add emoji to the message
        const templateInput = messageInput?.id;
        const text = document.getElementById(templateInput || '') as HTMLTextAreaElement;
        // value of the message
        const textValue = text.value;
        // cursor position
        const cursorPosition = text.selectionStart;
        text.value = getFormatTextAddEmoji(textValue, cursorPosition, emoji);
        handleChangeOption(text.value);
    };

    return (
        <AccordionDetails>
            <Draggable
                draggableId={`${props.variable_type}-variable-${props.index}`}
                index={props.index}
                key={`form-variable-${props.variable_type}-${props.index}`}
            >
                {(provided: DraggableProvided) => (
                    <Grid
                        container
                        alignContent={'center'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        width={'110%'}
                        m={.5}
                    >
                        <Grid item xs={.6} sx={{ alignSelf: 'center' }} >
                            <Tooltip title={t('form.tooltip_move_variable')} >
                                <IconButton
                                    aria-label='move-variable-option'
                                    size='small'
                                    {...provided.dragHandleProps}
                                >
                                    <Dehaze sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={10} sx={{ alignContent: 'center' }}>
                            <TextField
                                label={t('form.variable_value')}
                                id={`variable-${props.variable_type}-buttons-${props.index}`}
                                value={props.variable}
                                onChange={(e): void =>
                                    handleChangeOption(e.target.value)
                                }
                                multiline
                                inputRef={getMessageInput}
                                sx={{ width: '88%' }}
                                size="small"
                                inputProps={{ maxLength: 20 }}
                            />
                            <EmojiPickerComponent
                                onEmojiSelect={(e: string) => addEmoji(e)}
                                align={alignButton.bottom}
                            />
                            <Box className='remove-variable-option' sx={{ display: 'inline', alignContent: 'center' }}>
                                <Tooltip title={t('form.tooltip_remove_variable')}>
                                    <IconButton
                                        aria-label='remove-variable-option'
                                        onClick={(): void => {
                                            removeVariable(props.index);
                                        }}
                                        size='small'
                                        sx={{
                                            verticalAlign: '-webkit-baseline-middle',
                                            marginTop: '4px',
                                        }}
                                    >
                                        <Remove sx={{ fontSize: '1rem' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Draggable>
        </AccordionDetails>
    );
};