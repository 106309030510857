import { ClassicPreset as Classic } from 'rete';
import { IconButton } from '@mui/material';
import { AirlineStops } from '@mui/icons-material';
import { GotoData } from '../../models/Goto';
import { SubroutineData } from '../../models/Subroutine';
import { Module, DiContainer } from '../../types';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { clearEditor, mainParams, zoomAtNode } from '../../../util';
import { loadFlow } from '../../import_flow';

declare type GoSectionButtonControlOptions = {
    di: DiContainer;
    nodeData: GotoData | SubroutineData;
};

export class GoSectionButtonControl extends Classic.Control {
    constructor(public options?: GoSectionButtonControlOptions) {
        super();
    }
}

export function GoSectionButton(props: { data: GoSectionButtonControl }): JSX.Element {
    const [, botMxid] = mainParams();
    const [modules] = useLocalStorage<Module[]>(botMxid, []);
    const [, setSelectedModule] = useLocalStorage(`selected_module-${botMxid}`, 'Main');

    const openModule = async (module: Module): Promise<void> => {
        await clearEditor(props.data.options?.di.editor!);
        await loadFlow(props.data.options?.di!, module.content);
        props.data.options?.di.arranger();
    };

    const goSection = () => {
        const nodes = modules.flatMap((module) => module.content);
        let targetModule = '';
        let nodeID: string = '';
        if (props.data.options?.nodeData && 'o_connection' in props.data.options.nodeData) {
            nodeID = (props.data.options.nodeData as GotoData).o_connection || '';
            targetModule = nodes.find((node) => node.id === nodeID)?.module || '';
        } else if (props.data.options?.nodeData && 'go_sub' in props.data.options.nodeData) {
            nodeID = (props.data.options.nodeData as SubroutineData).go_sub || '';
            targetModule = nodes.find((node) => node.id === nodeID)?.module || '';
        }

        if (targetModule === '' || nodeID === '') return;
        const targetModuleObj = modules.find((module) => module.label === targetModule);
        setSelectedModule(targetModule);
        if (targetModuleObj) openModule(targetModuleObj);
        setTimeout(() => zoomAtNode(nodeID, props.data.options?.di!), 1000);
    };

    return (
        <>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='goto-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={goSection}
            >
                <AirlineStops fontSize="medium" />
            </IconButton>
        </>
    );
}