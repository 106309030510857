import { ClassicPreset as Classic } from 'rete';
import { InputControl, FormModalControl } from '../controls';
import { DiContainer } from '../types';
import { FormData } from '../models/Form';

const socket = new Classic.Socket('socket');

declare type FormNodeData = {
    node_id: string;
    height?: number;
    form_data?: FormData;
};

export class FormNode extends Classic.Node {
    width = 220;
    height = 180;
    constructor(di: DiContainer, nodeData?: FormNodeData) {
        super('Form');

        this.height = nodeData?.height || 180;
        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('default', new Classic.Output(socket, 'default', false));
        this.addControl(
            'node_id',
            new InputControl(
                {
                    hidden: true,
                    placeholder: 'ID',
                    value: nodeData?.node_id ? nodeData?.node_id : this.id,
                }
            )
        );
        this.addControl(
            'modal',
            new FormModalControl({
                di,
                nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' },
                socket,
                modalInfo: nodeData?.form_data,
            })
        );
    }
}