import { ClassicPreset as Classic } from 'rete';
import { InputControl, SetVarsModalControl } from '../controls';
import { DiContainer } from '../types';
import { SetVarsData } from '../models/SetVars';

declare type SetVarsNodeData = {
    node_id: string;
    set_vars_data: SetVarsData;
};

const socket = new Classic.Socket('socket');
export class SetVarsNode extends Classic.Node {
    width = 220;
    height = 180;
    constructor(di: DiContainer, nodeData?: SetVarsNodeData) {
        super('Set Vars');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl('modal', new SetVarsModalControl({ di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket, modalInfo: nodeData?.set_vars_data }));
    }
}