import React from 'react';
import { Grid, TextField, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { FormData } from '../../models/Form';
import JinjaCodeMirrorEditor from '../JinjaEditor';
import { Help } from '@mui/icons-material';

const FormTemplateData = (
    props: {
        formData: FormData;
        setFormData: (formData: FormData) => void;
        setEnableValidationFail: (enableValidationFail: boolean) => void;
    }
): JSX.Element => {

    return (
        <Grid
            item
            xs={12}
            className='form-modal-container'
        >
            <Grid container spacing={0} className='section-container'>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        label={t('form.name')}
                        id='node-name'
                        value={props.formData.name ?? ''}
                        required
                        onChange={
                            (e): void => props.setFormData({
                                ...props.formData, name: e.target.value,
                            })
                        }
                        sx={{ m: 1, width: '95%' }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        autoFocus
                        label={t('form.template_name')}
                        id='node-name'
                        value={props.formData.template_name ?? ''}
                        required
                        onChange={
                            (e): void => props.setFormData({
                                ...props.formData,
                                template_name: e.target.value,
                            })
                        }
                        sx={{ m: 1, width: '96%' }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        autoFocus
                        label={t('form.language')}
                        id='node-name'
                        value={props.formData.language ?? ''}
                        required
                        onChange={
                            (e): void => props.setFormData({
                                ...props.formData,
                                language: e.target.value,
                            })
                        }
                        sx={{ mt: 1, width: '88%' }}
                        size="small"
                    />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            label={t('form.variable')}
                            id="validation"
                            value={props.formData.variable ?? ''}
                            onChange={(e): void => props.setFormData({ ...props.formData, variable: e.target.value })}
                            multiline
                            size="small"
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('form.tooltip_variable_name')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            label={t('form.validation_attempts')}
                            id="validation_attempts"
                            value={props.formData.validation_fail?.attempts ?? ''}
                            onChange={(e): void => {
                                const status = e.target.value !== '' ? true : false;
                                props.setEnableValidationFail(status);
                                props.setFormData(
                                    {
                                        ...props.formData,
                                        validation_fail: {
                                            ...props.formData.validation_fail,
                                            attempts: e.target.value,
                                        },
                                    }
                                );
                            }}
                            type='number'
                            size="small"
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('form.tooltip_validation_attempts')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={11} pt={'2rem'}>
                        <JinjaCodeMirrorEditor
                            label={'form.validation'}
                            jinjaExpression={props.formData.validation ?? ''}
                            onChangeFunction={(value: string): void => props.setFormData({ ...props.formData, validation: value })}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('form.tooltip_validation')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FormTemplateData;
