import React, { useState } from 'react';
import { Box, Grid, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { FormData } from '../../models/Form';
import { Help } from '@mui/icons-material';
import EmojiPickerComponent, { alignButton } from '../EmojiPcker';
import { getFormatTextAddEmoji } from '../../../util/util';

const FormInactivityOptions = (
    props: {
        enableInactivityOptions: boolean;
        setEnableInactivityOptions: (enableInactivityOptions: boolean) => void;
        formData: FormData;
        setFormData: (formData: FormData) => void;
    }
) => {
    const [messageInput, setMessageInput] = useState<HTMLInputElement | null>(null);

    const handleChangeOption = (value: string) => {
        props.setFormData(
            {
                ...props.formData,
                inactivity_options:
                    { ...props.formData.inactivity_options, warning_message: value },
            }
        );
    };

    const addEmoji = (emoji: string): void => {
        //add emoji to the message
        const templateInput = messageInput?.id;
        const text = document.getElementById(templateInput || '') as HTMLTextAreaElement;
        // value of the message
        const textValue = text.value;
        // cursor position
        const cursorPosition = text.selectionStart;
        text.value = getFormatTextAddEmoji(textValue, cursorPosition, emoji);
        handleChangeOption(text.value);
    };

    const getMessageInput = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInput(input);
        }
    };

    return (
        <Grid container spacing={0} className='section-container'>
            {/*Inactivity options container where the user set the inactivity configurations*/}
            <Grid item xs={11}>
                <Typography
                    textAlign='left'
                    variant='h6'
                    sx={{ ml: 1, fontSize: '1.2rem' }}
                >
                    {t('form.inactivity_options')}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Box >
                    <Switch
                        size="small"
                        checked={props.enableInactivityOptions}
                        color='primary'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={(): void => props.setEnableInactivityOptions(!props.enableInactivityOptions)}
                        sx={{ ml: 1.5 }}
                    />
                </Box>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={11}>
                    <TextField
                        label={t('form.chat_timeout')}
                        id="chat-timeout"
                        type='number'
                        value={props.formData.inactivity_options?.chat_timeout ?? ''}
                        onChange={(e): void => props.setFormData(
                            {
                                ...props.formData,
                                inactivity_options:
                                    { ...props.formData.inactivity_options, chat_timeout: e.target.value },
                            }
                        )}
                        sx={{ mt: 1, mb: 1, ml: 1 }}
                        disabled={!props.enableInactivityOptions}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1} sx={{ alignContent: 'center' }}>
                    <div style={{ width: 'min-content' }}>
                        <Tooltip title={t('form.tooltip_chat_timeout')} arrow>
                            <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={10}>
                    <TextField
                        label={t('form.attempts')}
                        id="attempts"
                        type='number'
                        value={props.formData.inactivity_options?.attempts ?? ''}
                        onChange={(e): void => props.setFormData({
                            ...props.formData,
                            inactivity_options: {
                                ...props.formData.inactivity_options, attempts: e.target.value,
                            },
                        })}
                        sx={{ mt: 1, ml: 1, mb: 1, width: '100%' }}
                        fullWidth
                        disabled={!props.enableInactivityOptions}
                        size="small"
                    />
                </Grid>
                <Grid item xs={1} sx={{ alignContent: 'center' }}>
                    <div style={{ width: 'min-content' }}>
                        <Tooltip title={t('form.tooltip_attempts')} arrow>
                            <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', cursor: 'pointer', ml: 2 }} />
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item xs={10}>
                    <TextField
                        label={t('form.time_between_attempts')}
                        id="time-between-attempts"
                        type='number'
                        value={props.formData.inactivity_options?.time_between_attempts ?? ''}
                        onChange={(e): void => props.setFormData({
                            ...props.formData,
                            inactivity_options: {
                                ...props.formData.inactivity_options, time_between_attempts: e.target.value,
                            },
                        })}
                        sx={{ mt: 1, ml: 1, width: '100%' }}
                        disabled={!props.enableInactivityOptions}
                        size="small"
                    />
                </Grid>
                <Grid item xs={1} sx={{ alignContent: 'center' }}>
                    <div style={{ width: 'min-content' }}>
                        <Tooltip title={t('form.tooltip_time_between_attempts')} arrow>
                            <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', cursor: 'pointer', ml: 2 }} />
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={10}>
                    <TextField
                        label={t('form.warning_message')}
                        id="warning-message"
                        value={props.formData.inactivity_options?.warning_message ?? ''}
                        onChange={(e): void => handleChangeOption(e.target.value)}
                        sx={{ mt: 1, ml: 1, mb: 1 }}
                        disabled={!props.enableInactivityOptions}
                        multiline
                        inputRef={getMessageInput}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={1} sx={{ alignContent: 'center' }}>
                    <EmojiPickerComponent
                        onEmojiSelect={(e: string) => addEmoji(e)}
                        align={alignButton.bottom}
                        disabled={!props.enableInactivityOptions}
                    />
                </Grid>
                <Grid item xs={1} sx={{ alignContent: 'center' }}>
                    <div style={{ width: 'min-content' }}>
                        <Tooltip title={t('form.tooltip_warning_message')} arrow>
                            <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FormInactivityOptions;