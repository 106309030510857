import { ClassicPreset as Classic } from 'rete';
import { InputControl, CheckTimeModalControl } from '../controls';
import { DiContainer } from '../types';
import { CheckTimeData } from '../models/CheckTime';

declare type CheckTimeNodeData = {
    node_id: string;
    check_time_data: CheckTimeData;
};

const socket = new Classic.Socket('socket');
export class CheckTimeNode extends Classic.Node {
    width = 220;
    height = 230;
    constructor(di: DiContainer, nodeData?: CheckTimeNodeData) {
        super('Check Time');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('True', new Classic.Output(socket, 'true', false));
        this.addOutput('False', new Classic.Output(socket, 'false', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl(
            'modal',
            new CheckTimeModalControl(
                { nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, di: di, socket: socket, modalInfo: nodeData?.check_time_data }
            )
        );
    }
}