import { useState, useEffect, useRef } from 'react';
import {
    Button,
    Typography,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Tooltip,
    IconButton,
} from '@mui/material';
import { Save, Cancel } from '@mui/icons-material';
import { NodeEditor } from 'rete';
import { Schemes, Module } from '../rete/types';
import { mainParams } from '../util';
import { FlowService } from '../services/flow';
import { useTranslation } from 'react-i18next';
import { Flow } from '../rete/models/Flow';
import useLocalStorage from '../hooks/useLocalStorage';

const [menuflowDomain, botMxid] = mainParams();

declare type ExportButtonProps = {
    editor?: NodeEditor<Schemes>;
    showMainAlert: (message: string) => void;
    saveModule: () => void;
    unmarkModuleAsChanged: () => void;
};

export const ExportButton = (props: ExportButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [modules] = useLocalStorage<Module[]>(botMxid, []);

    const onOpen = () => () => {
        setOpen(true);
        props.saveModule();
    };

    const onClose = () => {
        setOpen(false);
    };

    const flowService = new FlowService(menuflowDomain, botMxid);

    async function save(): Promise<void> {
        const [current_flow, status] = await flowService.getFlow();

        if (status !== 200) {
            return;
        }

        const exportedNodes = modules.flatMap((module: Module) => module.content);
        const newFlow: Flow = {
            menu: {
                flow_variables: current_flow.flow.menu.flow_variables,
                nodes: exportedNodes || [],
            },
        };

        await flowService.updateFlow(current_flow.id, newFlow);
        setOpen(false);
        props.unmarkModuleAsChanged();
        props.showMainAlert(t('export_flow.flow_saved_successfully'));
    }

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Tooltip title={t('Save flow')}>
                <IconButton
                    size='small'
                    id='export_flow'
                    aria-label="Save"
                    onClick={onOpen()}
                    sx={{ ml: 1 }}
                    color='primary'
                >
                    <Save fontSize={'small'} />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        save();
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" variant='h6' textAlign={'center'} gutterBottom>
                    {t('export_flow.are_you_sure_you_want_to_export_the_flow?')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Alert severity='info' className='mt-16 mb-16'>
                            <Typography textAlign={'center'} variant='body2' gutterBottom>
                                {t('export_flow.all_the_changes_will_be_saved_and_the_flow_will_be_restarted_after_saved')}
                            </Typography>
                        </Alert>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('export_flow.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('export_flow.save_flow')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};