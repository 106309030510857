
import { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
} from '@mui/material';
import { WarningAmber, AppRegistration, Save, Cancel } from '@mui/icons-material';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_light';
import { FlowService } from '../services/flow';
import { mainParams } from '../util';
import { Flow } from '../rete/models/Flow';
import { useTranslation } from 'react-i18next';

declare type FlowVariablesProps = {
    showMainAlert: (message: string) => void;
};

const [menuflowDomain, botMxid] = mainParams();
const flowService = new FlowService(menuflowDomain, botMxid);

export const FlowVariables = (props: FlowVariablesProps): JSX.Element => {
    const { t } = useTranslation();
    const [flow, setFlow] = useState<Flow>();
    const [flowID, setFlowID] = useState<number>();
    const [flowVariablesData, setFlowVariablesData] = useState('{}');
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const fetchFlow = async (): Promise<object | undefined> => {
            const [flow, status] = await flowService.getFlow();
            if (status !== 200) {
                showErrorMessage(t('flow_variables.failed_to_fetch_flow_variables'));
                return;
            }

            setFlowID(flow.id);
            setFlow(flow.flow);
            setFlowVariablesData(JSON.stringify(flow.flow.menu.flow_variables, null, 2));
        };

        fetchFlow();
    }, []);

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const handleSubmit = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault();
        let variablesSerialized = {};
        try {
            variablesSerialized = JSON.parse(flowVariablesData);
        } catch (error) {
            showErrorMessage(t('flow_variables.invalid_json_format'));
            return;
        };

        const new_flow: Flow = { ...flow! };
        new_flow.menu = flow!.menu || {};
        new_flow.menu.flow_variables = variablesSerialized;

        await flowService.updateFlow(flowID!, new_flow);
        setOpen(false);
        props.showMainAlert(t('flow_variables.flow_variables_saved_successfully'));
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Tooltip title={t('Flow variables')}>
                <IconButton
                    size='small'
                    id='flow_variables'
                    aria-label="Flow variables"
                    onClick={onOpen()}
                    sx={{ ml: 1 }}
                    color='primary'
                >
                    <AppRegistration fontSize={'small'} />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                maxWidth={'lg'}
                fullWidth={true}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" variant='h6' textAlign={'center'} gutterBottom>
                    {t('flow_variables.flow_variables')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <AceEditor
                            placeholder={t('flow_variables.placeholder_text')}
                            mode="json"
                            theme="solarized_light"
                            name="blah2"
                            onChange={(value): void => setFlowVariablesData(value)}
                            fontSize={16}
                            lineHeight={22}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={flowVariablesData}
                            width={'100%'}
                            setOptions={{
                                useWorker: false,
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: true,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 2,
                            }} />
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('flow_variables.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('flow_variables.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};