import { NodeEditor } from 'rete';
import { Node, Schemes, Module, ImportNodeData, NodeID, ModalData } from '../rete/types';
import { mainParams, slugify_text } from './util';

const getModules = (botMxid: string): Module[] => {
    try {
        return JSON.parse(localStorage.getItem(botMxid) || '[]');
    } catch (error) {
        console.error('Failed to parse modules from localStorage:', error);
        return [];
    }
};

export const getNextNodeName = (
    nodeName: string,
    nodeType: string,
    editor: NodeEditor<Schemes>,
    node_subtype?: string
): string => {
    const [, botMxid] = mainParams();

    const modules = getModules(botMxid);
    const nodes = modules.flatMap((module: Module) => module.content) as ImportNodeData[];

    const filterNodes = (nodes: ImportNodeData[], type: string, subtype?: string): ImportNodeData[] => {
        return nodes.filter((node) => node.type === type && node.subtype === subtype);
    };

    const filteredNodes = filterNodes(nodes, nodeType, node_subtype);

    const selectedModule = JSON.parse(localStorage.getItem(`selected_module-${botMxid}`) || 'Main');
    const module = modules.find((module) => module.label === selectedModule);
    const filteredModuleNodes = filterNodes(module?.content as ImportNodeData[], nodeType, node_subtype);

    const editorNodes = editor.getNodes();
    const filteredEditorNodes = editorNodes.filter((node) => node.label === nodeName);
    const moduleNodesLength = filteredEditorNodes.length - filteredModuleNodes.length;

    const totalNodeCount = filteredNodes.length + moduleNodesLength;
    return `${nodeName}_${totalNodeCount}`;
};

export const nodeNameAlreadyExists = (name: string, node_id: NodeID, editor: NodeEditor<Schemes>): boolean => {
    const [, botMxid] = mainParams();
    const modules = getModules(botMxid);

    const modulesNodes = modules.flatMap((module: Module) => module.content) as ImportNodeData[];
    const editorNodes: Node[] = editor!.getNodes();
    if (modulesNodes.length === 0 && editorNodes.length === 0) return false;

    const nameExistsInNodes = modulesNodes.some(
        (node) => {
            return slugify_text(node.name) === slugify_text(name) && node.id !== node_id.menuflowNodeId;
        }
    );

    const nameExistsInEditor = editorNodes.some(
        (node) => slugify_text((node?.controls?.modal as ModalData)?.options?.modalInfo?.name) === slugify_text(name) && node.id !== node_id.reteNodeId
    );

    return nameExistsInNodes || nameExistsInEditor;
};