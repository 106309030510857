import { useEffect, useRef } from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
} from '@mui/material';
import { Cancel, SettingsBackupRestore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type ConfirmLoadBackupModalProps = {
    open: boolean;
    onClose: () => void;
    confirm: (confirm: boolean) => void;
    changedModules: string[];
};

export const ConfirmLoadBackupModal = (props: ConfirmLoadBackupModalProps): JSX.Element => {
    const { t } = useTranslation();

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.open]);

    const restoreBackup = (): void => {
        props.confirm(true);
    };

    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            scroll={'paper'}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    restoreBackup();
                },
            }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-restore-changed-modules"
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                {t('confirm_load_backup_modal.title')}
                <span className='close' onClick={props.onClose}>
                    &times;
                </span>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Box
                    id="scroll-dialog-restore-changed-modules"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Typography variant='body1'>
                        {t('confirm_load_backup_modal.restore_changed_modules')}
                        <span className='changed-modules'>{props.changedModules.join(', ')}</span>
                    </Typography>
                    <Typography variant='body1'>
                        {t('confirm_load_backup_modal.restore_backup_question')}
                    </Typography>
                    <Typography variant='body1' color='#b5b919'>
                        {t('confirm_load_backup_modal.restore_backup_warning')}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" startIcon={<Cancel />} onClick={props.onClose}>{t('confirm_load_backup_modal.cancel')}</Button>
                <Button type="submit" variant="contained" startIcon={<SettingsBackupRestore />}>{t('confirm_load_backup_modal.restore')}</Button>
            </DialogActions>
        </Dialog>
    );
};