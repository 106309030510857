import { ClassicPreset as Classic } from 'rete';
import { InputControl, EmailModalControl } from '../controls';
import { DiContainer } from '../types';
import { EmailData } from '../models/Email';

const socket = new Classic.Socket('socket');

declare type EmailNodeData = {
    node_id: string;
    nodeData?: EmailData;
};

export class EmailNode extends Classic.Node {
    width = 220;
    height = 180;
    constructor(di: DiContainer, nodeData?: EmailNodeData) {
        super('Email');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl(
            'modal',
            new EmailModalControl(
                { di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket, modalInfo: nodeData?.nodeData }
            )
        );
    }
}