import { useState, useEffect } from 'react';

import { Alert, Box, IconButton, Tooltip, Typography, TextField, InputAdornment, Tab, Tabs, TabProps } from '@mui/material';
import { Add, Clear, Remove } from '@mui/icons-material';
import { Module, NodeData } from '../../rete/types';
import { NewModuleModal } from './NewModuleModal';
import { RemoveModuleModal } from './RemoveModuleModal';
import { slugify_text, mainParams, clearEditor, openModule} from '../../util';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SearchIcon from '@mui/icons-material/Search';

import { ExportButton, FlowVariables, FullScreen, SearchNode } from '../../components';
import { createEditor as editor } from '../../rete/editor';
import { useRete } from 'rete-react-plugin';
import { FlowService } from '../../services';
import { export_flow } from '../../rete/export_flow';
import { tabsClasses } from '@mui/material/Tabs';
import { RestoreFlow } from '../RestoreFlow';
import { DndContext, useSensor, useSensors, PointerSensor, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';

const drawerWidth = 240;
const [menuflowDomain, botMxid] = mainParams();
const flowService = new FlowService(menuflowDomain, botMxid);

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

/**
 * Main component that manages the user interface for the list of modules.
 * Uses a drawer to display navigation options and buttons for exporting, managing flow variables and full screen.
 * export, manage flow variables and full screen.
 */
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean }>(({ theme }) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            },
        },
    ],
}));

/**
 * Styled component for the application's AppBar.
 * Manages the transition and width adjustments when the drawer is opened or closed.
 */
const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: `${drawerWidth}px`,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

/**
 * Styled component for the header of the drawer.
 * Ensures proper alignment and spacing for the content within the drawer.
 * It also includes necessary styles to position content below the app bar.
 */
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: '40px !important',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AntTab = styled((props: TabProps & { selected?: boolean }) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        maxHeight: '32px',
        padding: '0 12px',
        minHeight: '28px',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
            backgroundColor: 'rgba(64, 169, 255, 0.05)',
            borderRadius: '8px 8px 0 0',
        },
        '&.Mui-selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: 'rgba(24, 143, 255, 0.1)',
            borderRadius: '8px 8px 0 0',
            transition: 'all 0.2s ease-in-out',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '2px',
                backgroundColor: '#1890ff',
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }),
);

interface SortableTabProps {
    tab: Module;
    handleTabClose: (moduleLabel: string) => void;
    isChanged: boolean;
    handleTabChange: (event: React.SyntheticEvent, value: number) => void;
    selectedModule: string;
    openTabs: Module[];
}

const SortableTab = ({ tab, handleTabClose, isChanged, handleTabChange, selectedModule, openTabs }: SortableTabProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: tab.label });
    const { t } = useTranslation();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="sortable-tab-wrapper">
            <AntTab
                onClick={(e) => handleTabChange(e, openTabs.findIndex(t => t.label === tab.label))}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isChanged && <Tooltip title={t('changes in module') + ' ' + tab.label}>
                            <span className='module-tab-marker-changes-indicator'></span>
                        </Tooltip>}
                        {tab.label}
                        <div className='close-tab-button' onClick={(e) => {
                            e.stopPropagation();
                            handleTabClose(tab.label);
                        }}>
                            <Clear fontSize="small" sx={{ width: '16px', height: '16px' }} />
                        </div>
                    </Box>
                }
                value={openTabs.findIndex(t => t.label === tab.label)}
                selected={tab.label === selectedModule}
            />
        </div>
    );
};

export const ModuleList = (): JSX.Element => {
    const [ref, editorProps] = useRete(editor);
    const [showAlert, setshowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [modules, setModules] = useLocalStorage<Module[]>(botMxid, []);
    const [selectedModule, setSelectedModule] = useLocalStorage(`selected_module-${botMxid}`, 'Main');
    const [flowId, setFlowId] = useLocalStorage<number | undefined>(`flow_id-${botMxid}`, undefined);
    const [updateComponentBackupFlow, setUpdateComponentBackupFlow] = useState(false);

    const [openNewModal, setOpenNewModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [module, setModule] = useLocalStorage<Module[]>(botMxid, []);
    const [changedModules, setChangedModules] = useLocalStorage<string[]>(`changedModules-${botMxid}`, []);
    const { t } = useTranslation();

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [openTabs, setOpenTabs] = useState<Module[]>([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const filteredModules = modules.filter((module) => {
        return module.label.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const addTab = (module: Module) => {
        if (!openTabs.some(tab => tab.label === module.label)) {
            setOpenTabs([...openTabs, module]);
        }
    };

    const removeTab = (moduleLabel: string) => {
        const newOpenTabs = openTabs.filter(tab => tab.label !== moduleLabel);
        setOpenTabs(newOpenTabs);

        if (selectedModule === moduleLabel && newOpenTabs.length > 0) {
            const lastTab = newOpenTabs[newOpenTabs.length - 1];
            updateSelectedModule(lastTab.label);
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const newModuleLabel = openTabs[newValue].label;
        if (checkModuleChanges(selectedModule)) saveModules(selectedModule);
        updateSelectedModule(newModuleLabel);
        setSelectedTabIndex(newValue);
    };

    const checkModuleChanges = (moduleName: string): boolean => {
        const module = modules.find((mod) => mod.label === moduleName);
        const nodesInModule = module?.content;
        const nodesInEditor = editorProps?.di.editor.getNodes() || [];

        const hasChanges = nodesInModule?.length !== nodesInEditor.length;
        if (hasChanges) {
            markModuleAsChanged(moduleName);
        }
        return hasChanges;
    };

    const updateSelectedModule = (module: string): void => {
        checkModuleChanges(selectedModule);
        setSelectedModule(module);
        const selectedModuleData = modules.find((mod) => mod.label === module);
        if (selectedModuleData) {
            openModule(selectedModuleData, editorProps!.di);
            addTab(selectedModuleData);
            const tabIndex = openTabs.findIndex(tab => tab.label === module);
            if (tabIndex !== -1) {
                setSelectedTabIndex(tabIndex);
            }
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const saveModule = (moduleName: string): void => {
        const newModule = { label: moduleName, content: [] };
        if (!module.some((mod) => mod.label === moduleName)) {
            setModule([...module, newModule]);
        }
        setSelectedModule(moduleName);
        setOpenNewModal(false);

        // Limpiar el editor y abrir el nuevo módulo
        clearEditor(editorProps!.di.editor).then(() => {
            openModule(newModule, editorProps!.di);
            addTab(newModule);
            updateSelectedModule(moduleName);
        });
    };

    const removeModule = (moduleValue: string): void => {
        const cleanModules = module.filter((module: Module) => slugify_text(module.label) !== slugify_text(moduleValue));
        setModule(cleanModules);
        setOpenRemoveModal(false);

        removeTab(moduleValue);
    };

    const saveModules = (moduleName: string): void => {
        const exportedNodes = export_flow(moduleName, editorProps!.di.editor);
        const modulesCopy = modules.slice();
        const moduleIndex = modulesCopy.findIndex((module: Module) => module.label === moduleName);
        modulesCopy[moduleIndex] = { label: moduleName, content: exportedNodes };
        setModules([...modulesCopy]);
    };

    const showMainAlert = (message: string): void => {
        setAlertMessage(message);
        setshowAlert(true);
        setTimeout(() => setshowAlert(false), 3000);
    };

    function generateModules(nodes: NodeData[]): Module[] {
        const grouped = nodes.reduce((result: Module[], item: NodeData) => {
            const keyValue = item['module'] || 'Main';

            let group = result.find(g => g.label === keyValue);
            if (!group) {
                group = { label: keyValue, content: [] };
                result.push(group);
            }

            group.content.push(item);
            return result;
        }, [] as Module[]);

        return grouped.length > 0 ? grouped : [{ 'label': 'Main', 'content': [] }];
    };

    const getModules = async (): Promise<Module[]> => {
        const [flow, status] = await flowService.getFlow();
        if (status !== 200) {
            console.error('Error loading flow');
            return [];
        }
        setFlowId(flow.id);

        const nodes = flow.flow.menu.nodes;
        return generateModules(nodes);
    };

    useEffect(() => {
        const retriveModules = async () => {
            const modulesRetrieved = await getModules();
            const uniqueModules = modulesRetrieved.filter((mod, index, self) =>
                index === self.findIndex((m) => m.label === mod.label)
            );
            setModules(uniqueModules);

            // Asegurarse de que el módulo Main existe
            const mainModule = uniqueModules.find((module: Module) => module.label === 'Main');
            if (mainModule) {
                setSelectedModule('Main');
                setSelectedTabIndex(0);
                openModule(mainModule, editorProps!.di);
                setOpenTabs([mainModule]);
            } else if (uniqueModules.length > 0) {
                const firstModule = uniqueModules[0];
                setSelectedModule(firstModule.label);
                setSelectedTabIndex(0);
                openModule(firstModule, editorProps!.di);
                setOpenTabs([firstModule]);
            }
        };

        if (editorProps) retriveModules();
    }, [editorProps]);

    useEffect(() => {
        if (editorProps) {
            // If the selected module change in another location of the app, add the tab if it is not already open
            const isTabOpen = openTabs.find((tab) => tab.label === selectedModule);
            if (!isTabOpen) {
                const module = modules.find((mod) => mod.label === selectedModule);
                if (module) addTab(module);
            }

            const updateModuleSelection = () => {
                // Remover la clase de todos los elementos
                document.querySelectorAll('.moduleTabButton').forEach((element) => {
                    element.classList.remove('selectedModuleTabButton');
                });

                // Añadir la clase al elemento seleccionado
                const selectedElement = document.getElementById(selectedModule);
                if (selectedElement instanceof HTMLElement) {
                    selectedElement.classList.add('selectedModuleTabButton');
                }
            };

            updateModuleSelection();
        }
    }, [selectedModule, editorProps, filteredModules]);

    const handleTabClose = (moduleLabel: string) => {
        if (openTabs.length > 1 || selectedModule !== moduleLabel) {
            removeTab(moduleLabel);
        }
    };

    const markModuleAsChanged = (moduleName: string): void => {
        const _changedModules = [...changedModules];
        if (!changedModules.includes(moduleName)) {
            _changedModules.push(moduleName);
            setChangedModules(_changedModules);
        }
    };

    const unmarkModuleAsChanged = (): void => {
        setChangedModules([]);
        setUpdateComponentBackupFlow(!updateComponentBackupFlow);
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = openTabs.findIndex(tab => tab.label === active.id);
            const newIndex = openTabs.findIndex(tab => tab.label === over.id);

            if (oldIndex !== -1 && newIndex !== -1) {
                setOpenTabs(tabs => arrayMove(tabs, oldIndex, newIndex));
            }
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar sx={{ '& .MuiToolbar-root': { background: '#ffffff00', minHeight: '40px !important' } }}>
                        <Tooltip title={t('Open options')}>
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={[
                                    {
                                        mr: 2,
                                    },
                                    open && { display: 'none' },
                                ]}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        {showAlert &&
                            <Alert severity='success' className='main-alert'>
                                <Typography textAlign={'center'} variant='body1' gutterBottom>
                                    {alertMessage}
                                </Typography>
                            </Alert>
                        }
                        <Box className={'export-button'}>
                            <ExportButton
                                editor={editorProps?.di.editor}
                                saveModule={() => saveModules(selectedModule)}
                                showMainAlert={showMainAlert}
                                unmarkModuleAsChanged={unmarkModuleAsChanged}
                            />
                        </Box>
                        <Box className={'flow-variables-button'}>
                            <FlowVariables showMainAlert={showMainAlert} />
                        </Box>
                        <Box className={'full-screen-button'}>
                            <FullScreen />
                        </Box>
                        <Box className={'restore-backup-flow-button'}>
                            <RestoreFlow
                                flow_id={flowId}
                                openModule={openModule}
                                generateModules={generateModules}
                                updateComponentBackupFlow={updateComponentBackupFlow}
                                changedModules={changedModules}
                                setChangedModules={setChangedModules}
                                di={editorProps?.di}
                            />
                        </Box>
                        <Box className={'search-node-button'}>
                            <SearchNode
                                modules={modules}
                                setSelectedModule={setSelectedModule}
                                di={editorProps?.di}
                            />
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                maxWidth: '85%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                            }}
                        >
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                                modifiers={[restrictToHorizontalAxis]}
                            >
                                <SortableContext
                                    items={openTabs.map(tab => tab.label)}
                                    strategy={horizontalListSortingStrategy}
                                >
                                    <Tabs
                                        value={selectedTabIndex}
                                        onChange={handleTabChange}
                                        aria-label="module tabs"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        sx={{
                                            minHeight: '32px',
                                            '& .MuiTabs-flexContainer': {
                                                gap: '1px',
                                                minHeight: '32px',
                                            },
                                            [`& .${tabsClasses.scrollButtons}`]: {
                                                '&.Mui-disabled': { opacity: 0.3 },
                                                color: 'primary.main',
                                                height: '32px',
                                            },
                                            '& .MuiTabs-indicator': {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        {openTabs.map((tab, index) => {
                                            const isChanged = changedModules.includes(tab.label);
                                            return (
                                                <SortableTab
                                                    key={tab.label}
                                                    tab={tab}
                                                    handleTabClose={handleTabClose}
                                                    isChanged={isChanged}
                                                    handleTabChange={(e) => handleTabChange(e, index)}
                                                    selectedModule={selectedModule}
                                                    openTabs={openTabs}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                </SortableContext>
                            </DndContext>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <Box sx={{ display: 'flex' }}>
                        <DrawerHeader sx={{ minHeight: '40px !important' }}>
                            <Typography variant='h6' textAlign={'left'} gutterBottom>
                                Menuflow
                            </Typography>
                            <Tooltip title={t('Close options')}>
                                <IconButton onClick={handleDrawerClose} sx={{ ml: '5rem' }} color='info'>
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>
                            </Tooltip>
                        </DrawerHeader>
                    </Box>
                    <Divider />
                    <Box sx={{ m: 1, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>
                            {t('Modules')}
                        </Typography>
                        <Box sx={{ mr: 2 }}>
                            <Tooltip title={t('new_module')}>
                                <IconButton
                                    size='small'
                                    aria-label="add-module"
                                    className='moduleIconButton'
                                    onClick={() => setOpenNewModal(true)}
                                    sx={{ ml: 1 }}
                                >
                                    <Add fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('remove_module')}>
                                <IconButton
                                    size='small'
                                    aria-label="remove-module"
                                    className='moduleIconButton'
                                    onClick={() => setOpenRemoveModal(true)}
                                    sx={{ ml: 1 }}
                                >
                                    <Remove fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{ m: 1 }}>
                        <TextField
                            id="input-with-icon-textfield"
                            label={t('Search modeles')}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize={'small'} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size='small'
                                            sx={{ height: '24px', width: '24px' }}
                                            aria-label="clear-search"
                                            onClick={() => setSearchTerm('')}
                                        >
                                            <Tooltip title={t('clear')}>
                                                <Clear fontSize={'small'} />
                                            </Tooltip>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <List sx={{ overflow: 'auto', maxHeight: '88vh' }}>
                        {filteredModules.map((module: Module, index: number) => (
                            <ListItem
                                key={`module-${index}`}
                                disablePadding
                                id={module.label}
                                className='moduleTabButton'
                                onClick={() => {
                                    updateSelectedModule(module.label);
                                }}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AccountTreeIcon />
                                    </ListItemIcon>
                                    {module.label}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    {/* TODO: new features */}
                </Drawer>
            </Box>
            <Main open={open}>
                <DrawerHeader sx={{ minHeight: '40px !important' }} />
                <div className='container-editor'>
                    <div ref={ref} className="rete"></div>
                </div>
            </Main>
            <NewModuleModal open={openNewModal} onClose={() => setOpenNewModal(false)} save={saveModule} />
            <RemoveModuleModal open={openRemoveModal} models={module} onClose={() => setOpenRemoveModal(false)} remove={removeModule} />
        </>
    );
};