import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { FormData } from '../../models/Form';
import { reorder } from '../utils/reorderOnDragList';
import { FormVariablesComponent } from './FormVariablesComponent';

const FormVariablesContainer = (
    props: {
        formData: FormData;
        setFormData: (formData: FormData) => void;
        variable_type: 'body' | 'header' | 'button';
    }
): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const getFormVariables = (): string[] => {
        if (props.variable_type === 'body') {
            return props.formData.body_variables;
        }
        if (props.variable_type === 'header') {
            return props.formData.header_variables;
        }
        if (props.variable_type === 'button') {
            return props.formData.button_variables;
        }
        return [];
    };

    const [variables, setVariables] = useState<string[]>(getFormVariables());

    useEffect(() => {
        changeFormVariables(variables, false);
    }, [variables]);

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    const concatFormVariables = (
        variables: string | string[], formVariables: string[] | undefined = []
    ): string[] => {
        if (typeof variables === 'string') {
            return [...formVariables, variables];
        }

        return [...formVariables, ...variables];
    };

    const getConcatVariables = (
        variablesToChange: string | string[], OldVariables: string[], concatOldVariables: boolean
    ): string[] => {
        if (concatOldVariables) {
            return concatFormVariables(
                variablesToChange, OldVariables
            );
        } else {
            return concatFormVariables(variablesToChange);
        }
    };

    const changeFormVariables = (
        variablesToChange: string | string[],
        concatOldVariables: boolean
    ): void => {
        if (props.variable_type === 'body') {
            const bodyVariables = getConcatVariables(
                variablesToChange, props.formData.body_variables, concatOldVariables
            );

            props.setFormData({
                ...props.formData,
                body_variables: bodyVariables,
            });
        }
        if (props.variable_type === 'header') {
            const headerVariables = getConcatVariables(
                variablesToChange, props.formData.header_variables, concatOldVariables
            );

            props.setFormData({
                ...props.formData,
                header_variables: headerVariables,
            });
        }
        if (props.variable_type === 'button') {
            const buttonVariables = getConcatVariables(
                variablesToChange, props.formData.button_variables, concatOldVariables
            );

            props.setFormData({
                ...props.formData,
                button_variables: buttonVariables,
            });
        }
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const formVariables = getFormVariables();
        const variables:
            string[] = reorder(
                formVariables,
                result.source.index,
                result.destination.index
            );

        setVariables(variables);
    };

    const renderVariables = (): JSX.Element[] => {
        const formVariables = getFormVariables();

        return formVariables.map((variable, index) => {
            return (
                <Grid
                    item
                    xs={12}
                    key={`variable-${props.variable_type}-${index}`}
                >
                    <FormVariablesComponent
                        index={index}
                        variable_type={props.variable_type}
                        textFileWidth={'96%'}
                        variable={variable}
                        setVariables={setVariables}
                        variables={variables}
                    />
                </Grid>
            );
        });
    };

    return (
        <Grid container spacing={0} className='variables-container'>
            <Grid item xs={12}>
                <Accordion
                    expanded={expanded}
                    sx={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                    }}
                >
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={10} >
                                <Typography
                                    textAlign="left"
                                    variant='h6'
                                    sx={{ ml: 1, fontSize: '1.2rem' }}
                                >
                                    {t(`form.${props.variable_type}_variables`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} alignContent={'center'}>
                                <Box display={'flex'} justifyContent={'end'} >
                                    <Box className='round-button' margin={0}>
                                        <Tooltip title={t('form.tooltip_add_variable')}>
                                            <IconButton
                                                aria-label="round-button"
                                                onClick={() => setVariables((prev) => [...prev, ''])}
                                                sx={{ ml: 4 }}
                                            >
                                                <Add sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box className='expand-case-option'>
                                        <IconButton
                                            aria-label='expand-form-item'
                                            onClick={() => { handleExpandClick(); }}
                                            sx={{ ml: 1 }}
                                        >
                                            {expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                            {!expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`droppable-${props.variable_type}-variables`}>
                            {(provided: DroppableProvided) => (
                                <Grid
                                    container
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {
                                        renderVariables()
                                    }
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default FormVariablesContainer;