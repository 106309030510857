
import React, { useState, useEffect, useRef } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Button,
    Alert,
    IconButton,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save } from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import { MessageData } from '../../../models/Message';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../../components/TextEditor';
import { byMessage } from '../../../components/utils/configToolbarEditor';
import { clearMessage, getNextNodeName, nodeNameAlreadyExists } from '../../../../util';

declare type MessageModalControlOptions = {
    modalInfo?: MessageData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class MessageModalControl extends Classic.Control {
    constructor(public options?: MessageModalControlOptions) {
        super();
    }
}

export const MessageModal = (props: { data: MessageModalControl }): JSX.Element => {
    const [messageData, setMessageData] = useState<MessageData>({
        name: getNextNodeName('Message', 'message', props.data.options?.di.editor!),
        text: '',
        message_type: 'm.text',
    });
    const { t } = useTranslation();
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (props.data.options?.modalInfo && !open) setMessageData(props.data.options.modalInfo);
    }, [open, messageData]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined=> {
        if (messageData.name.length > 20) {
            return t('message.name_field_too_long');
        } else if (nodeNameAlreadyExists(messageData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('message.name_already_exists');
        }
        return;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const errorMessage = validateForm();
        if (errorMessage) {
            showErrorMessage(errorMessage);
            return;
        }

        props.data.options!.modalInfo = {
            name: messageData.name,
            text: clearMessage(messageData.text),
            message_type: messageData.message_type || 'm.text',
        };
        props.data.options?.di.updateNode(props.data.options?.nodeId.reteNodeId);
        onClose();
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('message.message_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <TextField
                            label={t('message.name')}
                            id='node-name'
                            value={messageData.name}
                            required
                            onChange={(e): void => setMessageData({ ...messageData, name: e.target.value })}
                            sx={{ m: 1, width: '48%' }}
                            size="small"
                        />
                        <TextField
                            autoFocus
                            label={t('message.message_type')}
                            id='message-type'
                            value={messageData.message_type}
                            onChange={(e): void => setMessageData({ ...messageData, message_type: e.target.value })}
                            sx={{ m: 1, width: '48%' }}
                            required
                            multiline
                            fullWidth
                            size="small"
                            defaultValue="m.text"
                            select
                        >
                            <MenuItem key='key-m-text' value={'m.text'}>
                                text
                            </MenuItem>
                            <MenuItem key='key-m-notice' value={'m.notice'}>
                                notice
                            </MenuItem>
                        </TextField>
                        <TextEditor
                            value={clearMessage(messageData.text)}
                            toolbarOptions={byMessage}
                            activeMarkdown={true}
                            onChange={(value: string): void => setMessageData({ ...messageData, text: value })}
                        />
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('message.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('message.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};