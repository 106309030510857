
import React, { useState, useEffect, useRef } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Button,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save } from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import { useTranslation } from 'react-i18next';
import { getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import { LocationData } from '../../../models/Location';

declare type LocationModalControlOptions = {
    modalInfo?: LocationData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class LocationModalControl extends Classic.Control {
    constructor(public options?: LocationModalControlOptions) {
        super();
    }
}

export const LocationModal = (props: { data: LocationModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [locationData, setLocationData] = useState<LocationData>({
        name: getNextNodeName('Location', 'location', props.data.options?.di.editor!),
        latitude: 0,
        longitude: 0,
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (props.data.options?.modalInfo && !open) {
            setLocationData(props.data.options.modalInfo);
        }
    }, [locationData, open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        if (locationData.name.length > 20) {
            return t('location.name_field_too_long');
        } else if (nodeNameAlreadyExists(locationData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('location.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }

        props.data.options!.modalInfo = locationData;
        props.data.options!.di.updateNode(props.data.options!.nodeId.reteNodeId);
        onClose();
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('location.location_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('location.required_fields')}
                        </Typography>
                        <TextField
                            label={t('location.name')}
                            id='node-name'
                            value={locationData.name}
                            required
                            onChange={(e): void => setLocationData({ ...locationData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <TextField
                            label={t('location.latitude')}
                            id='latitude'
                            value={locationData.latitude}
                            required
                            type='number'
                            onChange={(e): void => setLocationData({ ...locationData, latitude: parseFloat(e.target.value) })}
                            sx={{ m: 1, width: '47%' }}
                            size="small"
                        />
                        <TextField
                            label={t('location.longitude')}
                            id='longitude'
                            value={locationData.longitude}
                            required
                            type='number'
                            onChange={(e): void => setLocationData({ ...locationData, longitude: parseFloat(e.target.value) })}
                            sx={{ m: 1, width: '47%' }}
                            size="small"
                        />
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('location.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('location.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};