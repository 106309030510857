import { useCallback, useEffect, useState } from 'react';

// material-ui
import {
    Box,
    Tooltip,
    IconButton,
    Popover,
    List,
    ListSubheader,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Divider,
} from '@mui/material';

// project import
import { useTranslation } from 'react-i18next';

// assets
import { Cached, CloudSync, ExpandMore, SettingsBackupRestore } from '@mui/icons-material/';
import { FlowService } from '../services/flow';
import { formatDateToHumanReadable, mainParams } from '../util/util';
import { BackupContent, BackupFlow, DiContainer, Module, NodeData } from '../rete/types';
import { useLocalStorage } from 'usehooks-ts';
import { ConfirmLoadBackupModal } from './ConfirmLoadBackupModal';
// ==============================|| HEADER CONTENT - RestoreFlow ====================== //
const [menuflowDomain, botMxid] = mainParams();
const flowService = new FlowService(menuflowDomain, botMxid);

declare type RestoreFlowProps = {
    flow_id: number | undefined;
    openModule: (module: Module, di: DiContainer) => Promise<void>;
    generateModules: (nodes: NodeData[]) => Module[];
    updateComponentBackupFlow: boolean;
    changedModules: string[];
    setChangedModules: (changedModules: string[]) => void;
    di?: DiContainer;
};

export const RestoreFlow = (props: RestoreFlowProps) => {
    const [, setModules] = useLocalStorage<Module[]>(botMxid, []);
    const [selectedModule, setSelectedModule] = useLocalStorage(`selected_module-${botMxid}`, 'Main');
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [listBackupFlows, setListBackupFlows] = useState<BackupContent[]>([]);
    const [count, setCount] = useState<number>(0);
    const [limit] = useState<number>(10);
    const [offset, setOffset] = useState<number>(0);
    const [confirmLoadBackupModal, setConfirmLoadBackupModal] = useState<boolean>(false);
    const [pendingBackupId, setPendingBackupId] = useState<number | null>(null);
    const handleToggle = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        fetchBackupFlows();
    }, [offset]);

    useEffect(() => {
        setOffset(listBackupFlows.length);
    }, [props.updateComponentBackupFlow]);

    const fetchBackupFlows = async () => {
        if (!props.flow_id) {
            return;
        }
        const [backupFlows, status] = await flowService.getBackupFlows(props.flow_id, limit, offset);
        if (status !== 200) {
            console.error('Error fetching backup flows', status, backupFlows);
        } else {
            setCount((backupFlows as BackupFlow).count);
            const backupListCopy = [...listBackupFlows, ...(backupFlows as BackupFlow).flows];
            // sort by created_at
            backupListCopy.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            setListBackupFlows(backupListCopy);
        }
    };

    const handleRestoreFlow = async (backupFlowId: number) => {
        if (!props.flow_id) {
            return;
        }
        if (props.changedModules.length > 0) {
            // show alert with confirm button to restore changed modules
            setPendingBackupId(backupFlowId);
            setConfirmLoadBackupModal(true);
        } else {
            const [backupFlows, status] = await flowService.getBackupFlows(props.flow_id, limit, offset, backupFlowId);
            if (status !== 200) {
                console.error('Error fetching backup flows', status, backupFlows);
            } else {
                loadModules((backupFlows as BackupContent).flow?.menu.nodes || []);
            }
        }
    };

    const loadModules = async (listNodes: NodeData[]) => {
        const generatedModules = props.generateModules(listNodes);
        setModules(generatedModules);
        let currentModule = generatedModules.find((module: Module) => module.label === selectedModule);
        if (!currentModule) {
            setSelectedModule('Main');
            currentModule = generatedModules.find((module: Module) => module.label === 'Main');
        }
        await props.openModule(currentModule!, props.di!);
    };

    const handleLoadActualFlow = async () => {
        if (props.changedModules.length > 0) {
            setConfirmLoadBackupModal(true);
        } else {
            const [flow, status] = await flowService.getFlow();
            if (status !== 200) {
                console.error('Error fetching flow', status, flow);
            } else {
                loadModules((flow.flow.menu.nodes || []) as NodeData[]);
            }
        }
    };

    const confirmLoadBackup = async (confirm: boolean) => {
        if (confirm && pendingBackupId && props.flow_id) {
            const [backupFlows, status] = await flowService.getBackupFlows(props.flow_id, limit, offset, pendingBackupId);
            if (status !== 200) {
                console.error('Error fetching backup flows', status, backupFlows);
            } else {
                loadModules((backupFlows as BackupContent).flow?.menu.nodes || []);
            }
        }
        props.setChangedModules([]);
        setConfirmLoadBackupModal(false);
        setPendingBackupId(null);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <Tooltip title={t('Restore Flow')}>
                <IconButton
                    size='small'
                    id='full_screen'
                    aria-label="Fullscreen"
                    onClick={handleToggle}
                    sx={{ ml: 1 }}
                    aria-describedby={id}
                    color='primary'
                >
                    <CloudSync />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {t('Flow backup list')}
                        </ListSubheader>
                    }
                >
                    <ListItemButton key={'load-actual-flow'} onClick={() => handleLoadActualFlow()}>
                        <ListItemIcon sx={{ minWidth: '30px' }}>
                            <Cached />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('Current version')}
                            sx={{
                                height: 'min-content',
                                marginTop: '0px',
                                marginBottom: '0px',
                            }}
                        />
                    </ListItemButton>
                    <Divider />
                    <Box sx={{ maxHeight: '450px', overflow: 'auto' }}>
                        {listBackupFlows.map((backupFlow) => (
                            <ListItemButton key={backupFlow.id} onClick={() => handleRestoreFlow(backupFlow.id)}>
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    <SettingsBackupRestore />
                                </ListItemIcon>
                                {formatDateToHumanReadable(new Date(backupFlow.created_at)) != null ? (
                                    <ListItemText
                                        primary={backupFlow.created_at}
                                        secondary={formatDateToHumanReadable(new Date(backupFlow.created_at))}
                                        sx={{
                                            height: 'min-content',
                                            marginTop: '0px',
                                            marginBottom: '0px',
                                            '& .MuiListItemText-secondary': {
                                                fontSize: '10px',
                                            },
                                        }}
                                    />
                                ) : (
                                    <ListItemText primary={backupFlow.created_at} />
                                )}
                            </ListItemButton>
                        ))}
                    </Box>
                </List>
                <span className='showing-backup-flows'>
                    <Typography variant='caption'>
                        {t('Showing')} {listBackupFlows.length} {t('of')} {count}
                    </Typography>
                    <Tooltip title={t('Show more')} placement='top'>
                        <IconButton sx={{ ml: 1 }} aria-label="delete" size="small" onClick={() => {
                            setOffset(listBackupFlows.length);
                        }}>
                            <ExpandMore fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </span>
            </Popover>
            <ConfirmLoadBackupModal
                open={confirmLoadBackupModal}
                onClose={() => setConfirmLoadBackupModal(false)}
                confirm={confirmLoadBackup}
                changedModules={props.changedModules}
            />
        </Box>
    );
};
