import { ClassicPreset as Classic } from 'rete';
import { InputControl, InviteUserModalControl } from '../controls';
import { DiContainer } from '../types';
import { InviteUserData } from '../models/InviteUser';

const socket = new Classic.Socket('socket');

declare type InviteUserNodeData = {
    node_id: string;
    input_data: InviteUserData;
};
export class InviteUserNode extends Classic.Node {
    width = 220;
    height = 260;
    constructor(di: DiContainer, nodeData?: InviteUserNodeData) {
        super('Invite User');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('join', new Classic.Output(socket, 'join', false));
        this.addOutput('reject', new Classic.Output(socket, 'reject', false));
        this.addOutput('timeout', new Classic.Output(socket, 'timeout', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl('modal', new InviteUserModalControl({ nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, di: di, socket: socket, modalInfo: nodeData?.input_data }));
    }
}