
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    Button,
    Typography,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tab,
    Tabs,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Tooltip,
} from '@mui/material';
import {
    WarningAmber,
    Settings,
    Cancel,
    Save,
    DataObject,
    DynamicForm,
    Add,
    Remove,
    ExpandMore,
    ExpandLess,
} from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import AceEditor from 'react-ace';
import { ExportHttpRequestNode, HttpRequestData } from '../../../models/HttpRequest';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_light';
import { checkJinja2Syntaxis, nodeNameAlreadyExists, mainParams, getNextNodeName } from '../../../../util';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../../components/TabPanel';
import { FlowUtils } from '../../../../services';
import { Middleware } from '../../../../services/flow_utils';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { reorder } from '../../../components/utils/reorderOnDragList';
import { Case, ExportCase } from '../../../models/Switch';
import { CaseComponent } from '../switch_modal/CaseComponent';

declare type HttpRequestModalControlOptions = {
    modalInfo?: HttpRequestData;
    rawData?: ExportHttpRequestNode;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class HttpRequestModalControl extends Classic.Control {
    constructor(public options?: HttpRequestModalControlOptions) {
        super();
    }
}

export const HttpRequestModal = (props: { data: HttpRequestModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [httpRequestRawData, setHttpRequestRawData] = useState('{}');
    const [httpRequestData, setHttpRequestData] = useState<HttpRequestData>({
        name: getNextNodeName('HttpRequest','http_request', props.data.options?.di.editor!),
        middleware: '',
        method: '',
        url: '',
        json: '{}',
        query_params: [],
        headers: [
            { key: 'Content-Type', value: 'application/json' },
        ],
        variables: [],
        cases: [
            {'id': 'default', 'o_connection': '', 'variables': []},
        ],
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [middlewares, setMiddlewares] = useState<Middleware[]>([]);
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        const [menuflowDomain] = mainParams();
        const flowUtilsService = new FlowUtils(menuflowDomain);

        const getMiddlewares = async (): Promise<void> => {
            const [json, status] = await flowUtilsService.getMiddlewares();
            if (status === 200) {
                setMiddlewares(json);
            }
        };

        getMiddlewares();
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            setHttpRequestData(props.data.options.modalInfo);
            setHttpRequestRawData(JSON.stringify(props.data.options.rawData, null, 2));
        }
    }, [open, httpRequestRawData, httpRequestData]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const addNewHeader = (): void => {
        const headers = [...httpRequestData.headers || []];
        headers.push({ key: '', value: '' });
        setHttpRequestData({ ...httpRequestData, headers: headers });
    };

    const removeHeader = (index: number): void => {
        const headers = [...httpRequestData.headers || []];
        headers.splice(index, 1);
        setHttpRequestData({ ...httpRequestData, headers: headers });
    };

    const addNewQueryParams = (): void => {
        const query_params = [...httpRequestData.query_params || []];
        query_params.push({ key: '', value: '' });
        setHttpRequestData({ ...httpRequestData, query_params: query_params });
    };

    const removeQueryParam = (index: number): void => {
        const query_params = [...httpRequestData.query_params || []];
        query_params.splice(index, 1);
        setHttpRequestData({ ...httpRequestData, query_params: query_params });
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkAddConnection = (value: string): boolean => {
        // Check if the value of the connection is a jinja2 variable or the string 'finish'
        // It is used to determine if the connection should be added to the node
        return checkJinja2Syntaxis(value) || value === 'finish';
    };

    const updateOutputConnection = (): void => {
        const di = props.data.options!.di;
        const node = di.editor.getNode(props.data.options!.nodeId.reteNodeId);
        const socket = props.data.options!.socket;
        const outputIDs: string[] = [];
        const casesID: string[] = [];
        const cases: ExportCase[] = tabValue === 0 ? httpRequestData.cases : JSON.parse(httpRequestRawData).cases;

        if (node.outputs) {
            for (const output in node.outputs) {
                outputIDs.push(output);
            }
        }

        cases.forEach((item) => {
            if (!checkAddConnection(item.o_connection)) {
                casesID.push(item.id ||  item.case || '');
            }
        });

        outputIDs.map((output) => {
            if (!casesID.includes(output)) {
                node.removeOutput(output);
            }
        });

        cases.forEach((item) => {
            if (
                !outputIDs.includes(item.id || '') &&
                !checkAddConnection(item.o_connection)
            ) {
                const connection_id = item.id || '';
                node.addOutput(connection_id, new Classic.Output(socket, connection_id, false));
            }
        });
        di.updateNode(props.data.options!.nodeId.reteNodeId);
    };

    const updateNodeHeigth = (): void => {
        const cases: ExportCase[] = tabValue === 0 ? httpRequestData.cases : JSON.parse(httpRequestRawData).cases;
        const nodeHeigth = 180 + (cases.length * 30);
        const editor_node = props.data.options!.di.editor.getNode(props.data.options!.nodeId.reteNodeId);
        editor_node.height = nodeHeigth;
        props.data.options!.di.updateNode(props.data.options!.nodeId.reteNodeId );
    };

    const validateForm = (): string | undefined => {
        const nodeData = tabValue === 0 ? httpRequestData : JSON.parse(httpRequestRawData);

        if (!httpRequestRawData) {
            return t('http_request.http_data_can_not_be_empty');
        } else if (
            tabValue == 0 && !httpRequestData.name ||
            tabValue == 1 && !JSON.parse(httpRequestRawData).name
        ) {
            return t('http_request.name_field_is_required');
        } else if (
            tabValue == 0 && httpRequestData.name.length > 20 ||
            tabValue == 1 && JSON.parse(httpRequestRawData).name.length > 20
        ) {
            return t('http_request.name_field_too_long');
        } else if (nodeNameAlreadyExists(nodeData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('http_request.name_already_exists');
        } else if (nodeData.cases.length === 0) {
            return t('http_request.you_must_add_at_least_one_case');
        }

        return;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        try {
            const joinData = joinTabsData();
            const errorMessage = validateForm();
            if (errorMessage) {
                showErrorMessage(errorMessage);
                return;
            };

            props.data.options!.modalInfo = tabValue == 0 ? httpRequestData : joinData as HttpRequestData;
            props.data.options!.rawData = tabValue == 1 ? JSON.parse(httpRequestRawData) : joinData as ExportHttpRequestNode;
        } catch (error) {
            showErrorMessage(t('http_request.invalid_json_format'));
            return;
        }
        updateOutputConnection();
        updateNodeHeigth();
        onClose();
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const cases:
            Case[] = reorder(
                httpRequestData.cases,
                result.source.index,
                result.destination.index
            );

        setHttpRequestData({
            ...httpRequestData,
            cases: [...cases],
        });
    };

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    const addNewCase = (): void => {
        setHttpRequestData({
            ...httpRequestData,
            cases: [{ id: '', o_connection: '', variables: [] }, ...httpRequestData.cases],
        });
    };

    const removeCase = (index: number): void => {
        const newCases = [...httpRequestData.cases];
        newCases.splice(index, 1);
        setHttpRequestData({ ...httpRequestData, cases: newCases });
    };

    const saveCase = (index: number, key: 'id' | 'o_connection', value: string): void => {
        const newCases = [...httpRequestData.cases];
        newCases[index][key] = value;
        setHttpRequestData({ ...httpRequestData, cases: newCases });
    };

    const addCaseVariable = (caseIndex: number): void => {
        const newCases = [...httpRequestData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        setHttpRequestData({ ...httpRequestData, cases: newCases });
    };

    const saveCaseVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...httpRequestData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        setHttpRequestData({ ...httpRequestData, cases: newCases });
    };

    const removeCaseVariable = (caseIndex: number, index: number): void => {
        const newCases = [...httpRequestData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        setHttpRequestData({ ...httpRequestData, cases: newCases });
    };

    const addVariable = (): void => {
        const variables = [...httpRequestData.variables || []];
        variables.push({ key: '', value: '' });
        setHttpRequestData({ ...httpRequestData, variables: variables });
    };

    const removeVariable = (index: number): void => {
        const variables = [...httpRequestData.variables || []];
        variables.splice(index, 1);
        setHttpRequestData({ ...httpRequestData, variables: variables});
    };

    const listToObj = (list: { key: string; value: string }[]): { [key: string]: string } => {
        const obj: { [key: string]: string } = {};
        list.forEach((item) => {
            obj[item.key] = item.value;
        });
        return obj;
    };

    const objToList = (obj: { [key: string]: string }): { key: string; value: string }[] => {
        return Object.entries(obj).map(([key, value]) => ({ key, value }));
    };

    const joinTabsData = (): ExportHttpRequestNode | HttpRequestData | undefined => {
        if (tabValue === 0) {
            const parsedJson = JSON.parse(httpRequestRawData);
            const newData = {
                ...parsedJson,
                type: 'http_request',
                name: httpRequestData.name,
                middleware: httpRequestData.middleware,
                method: httpRequestData.method,
                url: httpRequestData.url,
                query_params: listToObj(httpRequestData.query_params || []),
                headers: listToObj(httpRequestData.headers || []),
                variables: listToObj(httpRequestData.variables || []),
                cases: httpRequestData.cases.map((item) => {
                    return {
                        id: item.id,
                        o_connection: item.o_connection,
                        variables: listToObj(item.variables || []),
                    };
                }),
            };
            if (httpRequestData.json) {
                newData.json = JSON.parse(httpRequestData.json);
            }
            setHttpRequestRawData(JSON.stringify(newData, null, 2));
            return newData;
        } else if (tabValue === 1) {
            const parsedJson: ExportHttpRequestNode = JSON.parse(httpRequestRawData);
            const newData = {
                name: parsedJson.name,
                middleware: parsedJson.middleware,
                method: parsedJson.method,
                url: parsedJson.url,
                json: JSON.stringify(parsedJson.json, null, 2) || JSON.stringify(parsedJson.data, null, 2) || '{}',
                query_params: objToList(parsedJson.query_params || {}),
                headers: objToList(parsedJson.headers || {}),
                variables: objToList(parsedJson.variables || {}),
                cases: parsedJson.cases.map((item) => {
                    return {
                        id: item.id || '',
                        o_connection: item.o_connection,
                        variables: objToList(item.variables || {}),
                    };
                }),
            };
            setHttpRequestData(newData);
            return newData;
        }

        return;
    };

    const handleTabs = (index: number): void => {
        joinTabsData();
        setTabValue(index);
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={'md'}
                fullWidth={true}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title"  textAlign={'center'} variant='h6' gutterBottom>
                    {t('http_request.http_request_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Tabs value={tabValue} onChange={(_, value) => handleTabs(value)} aria-label="icon label tabs example">
                            <Tab icon={<DynamicForm fontSize='small'/>} label="HTTP Editor" />
                            <Tab icon={<DataObject fontSize='small'/>} label="JSON Editor" />
                        </Tabs>
                        <TabPanel index={0} value={tabValue}>
                            <Box className={'section-container'}>
                                <TextField
                                    label={t('http_request.name')}
                                    id='node-name'
                                    value={httpRequestData.name}
                                    required
                                    onChange={(e): void => setHttpRequestData({ ...httpRequestData, name: e.target.value })}
                                    sx={{ m: 1, width: '98.5%' }}
                                    size="small"
                                />
                                <FormControl sx={{ m: 1, width: '15%' }} size={'small'}>
                                    <InputLabel id='method-label'>{t('http_request.method')}</InputLabel>
                                    <Select
                                        labelId='method-label'
                                        id='method-label'
                                        value={httpRequestData.method}
                                        required
                                        onChange={(e): void => setHttpRequestData({ ...httpRequestData, method: e.target.value })}
                                        label='Method'
                                    >
                                        <MenuItem value={'GET'}>GET</MenuItem>
                                        <MenuItem value={'POST'}>POST</MenuItem>
                                        <MenuItem value={'PATCH'}>PATCH</MenuItem>
                                        <MenuItem value={'PUT'}>PUT</MenuItem>
                                        <MenuItem value={'DELETE'}>DELETE</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label={t('http_request.url')}
                                    id='url'
                                    value={httpRequestData.url}
                                    required
                                    onChange={(e): void => setHttpRequestData({ ...httpRequestData, url: e.target.value })}
                                    sx={{ m: 1, width: '80.5%' }}
                                    size="small"
                                />
                                <FormControl sx={{ m: 1, width: '98.5%' }} size='small'>
                                    <InputLabel id='middlewares-label'>{t('http_request.middlewares')}</InputLabel>
                                    <Select
                                        labelId='middlewares-label'
                                        id='middlewares-select'
                                        value={httpRequestData.middleware}
                                        onChange={(e): void => setHttpRequestData({ ...httpRequestData, middleware: e.target.value })}
                                        label='Method'
                                    >
                                        {middlewares.map((middleware: {id: string, type: string}, index: number) => {
                                            if(['jwt', 'base', 'basic'].includes(middleware.type)) {
                                                return(
                                                    <MenuItem key={`${middleware.id}-${index}`} value={middleware.id}>
                                                        {middleware.id}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className={'section-container'}>
                                <Grid container spacing={0} sx={{ mb: 1 }}>
                                    <Grid item xs={10} >
                                        <Typography
                                            textAlign="left"
                                            variant='h6'
                                            sx={{ ml: 1, fontSize: '1.2rem' }}
                                        >
                                            {t('http_request.query_params')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Box className='round-button' textAlign={'end'}>
                                            <Tooltip title={t('http_request.tooltip_add_query_param')}>
                                                <IconButton
                                                    aria-label="add-new-query-param"
                                                    onClick={addNewQueryParams}
                                                    sx={{ ml: 4 }}
                                                >
                                                    <Add sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {httpRequestData.query_params?.map((param, index) => (
                                    <div key={`http_query_params_${index}`}>
                                        <TextField
                                            label={t('http_request.key')}
                                            id='key'
                                            value={param.key}
                                            required
                                            onChange={(e): void => {
                                                const query_params = [...httpRequestData.query_params || []];
                                                query_params[index].key = e.target.value;
                                                setHttpRequestData({ ...httpRequestData, query_params });
                                            }}
                                            sx={{ m: 1, width: '43%' }}
                                            size="small"
                                        />
                                        <TextField
                                            label={t('http_request.value')}
                                            id='value'
                                            value={param.value}
                                            required
                                            onChange={(e): void => {
                                                const query_params = [...httpRequestData.query_params || []];
                                                query_params[index].value = e.target.value;
                                                setHttpRequestData({ ...httpRequestData, query_params });
                                            }}
                                            sx={{ m: 1, width: '43%' }}
                                            size="small"
                                        />
                                        <Box className='round-button' sx={{ display: 'inline', verticalAlign: 'text-top' }}>
                                            <Tooltip title={t('http_request.tooltip_remove_query_param')} >
                                                <IconButton
                                                    aria-label="remove-query-param"
                                                    size='small'
                                                    sx={{ ml: 1.5, mt: 1 }}
                                                    onClick={(): void => removeQueryParam(index)}
                                                >
                                                    <Remove sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </div>
                                ))}
                            </Box>
                            <Box className={'section-container'}>
                                <Grid container spacing={0} sx={{ mb: 1 }}>
                                    <Grid item xs={10} >
                                        <Typography
                                            textAlign="left"
                                            variant='h6'
                                            sx={{ ml: 1, fontSize: '1.2rem' }}
                                        >
                                            {t('http_request.headers')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Box className='round-button' textAlign={'end'}>
                                            <Tooltip title={t('http_request.tooltip_add_header')}>
                                                <IconButton
                                                    aria-label="add-new-case"
                                                    onClick={addNewHeader}
                                                    sx={{ ml: 4 }}
                                                >
                                                    <Add sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {httpRequestData.headers?.map((header, index) => (
                                    <div key={`http_headers_${index}`}>
                                        <TextField
                                            label={t('http_request.key')}
                                            id='key'
                                            value={header.key}
                                            required
                                            onChange={(e): void => {
                                                const headers = [...httpRequestData.headers || []];
                                                headers[index].key = e.target.value;
                                                setHttpRequestData({ ...httpRequestData, headers });
                                            }}
                                            sx={{ m: 1, width: '43%' }}
                                            size="small"
                                        />
                                        <TextField
                                            label={t('http_request.value')}
                                            id='value'
                                            value={header.value}
                                            required
                                            onChange={(e): void => {
                                                const headers = [...httpRequestData.headers || []];
                                                headers[index].value = e.target.value;
                                                setHttpRequestData({ ...httpRequestData, headers });
                                            }}
                                            sx={{ m: 1, width: '43%' }}
                                            size="small"
                                        />
                                        <Box className='round-button' sx={{ display: 'inline', verticalAlign: 'text-top' }}>
                                            <Tooltip title={t('http_request.tooltip_remove_header')} >
                                                <IconButton
                                                    aria-label="remove-header"
                                                    size='small'
                                                    sx={{ ml: 1.5, mt: 1 }}
                                                    onClick={(): void => removeHeader(index)}
                                                >
                                                    <Remove sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </div>
                                ))}
                            </Box>
                            <Box className={'section-container'}>
                                <Typography
                                    textAlign="left"
                                    variant='h6'
                                    sx={{ ml: 1, mb: 2, fontSize: '1.2rem' }}
                                >
                                    {t('http_request.body')}
                                </Typography>
                                <AceEditor
                                    placeholder={t('http_request.placeholder_text')}
                                    mode="json"
                                    theme="solarized_light"
                                    name="blah2"
                                    onChange={(value): void => setHttpRequestData({ ...httpRequestData, json: value })}
                                    fontSize={16}
                                    lineHeight={22}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    value={httpRequestData.json}
                                    width={'100%'}
                                    height='30ch'
                                    setOptions={{
                                        useWorker: false,
                                        enableBasicAutocompletion: false,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2,
                                    }}
                                />
                            </Box>
                            <Box className={'section-container'}>
                                <Grid container spacing={0} sx={{ mb: 1 }}>
                                    <Grid item xs={10} >
                                        <Typography
                                            textAlign="left"
                                            variant='h6'
                                            sx={{ ml: 1, fontSize: '1.2rem' }}
                                        >
                                            {t('http_request.variables')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Box className='round-button' textAlign={'end'}>
                                            <Tooltip title={t('http_request.tooltip_add_variable')}>
                                                <IconButton
                                                    aria-label="add-new-variable"
                                                    onClick={addVariable}
                                                    sx={{ ml: 4 }}
                                                >
                                                    <Add sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {httpRequestData.variables?.map((variable, index) => (
                                    <div key={`http_variables_${index}`}>
                                        <TextField
                                            label={t('http_request.key')}
                                            id='key'
                                            value={variable.key}
                                            required
                                            onChange={(e): void => {
                                                const variables = [...httpRequestData.variables || []];
                                                variables[index].key = e.target.value;
                                                setHttpRequestData({ ...httpRequestData, variables });
                                            }}
                                            sx={{ m: 1, width: '43%' }}
                                            size="small"
                                        />
                                        <TextField
                                            label={t('http_request.value')}
                                            id='value'
                                            value={variable.value}
                                            required
                                            onChange={(e): void => {
                                                const variables = [...httpRequestData.variables || []];
                                                variables[index].value = e.target.value;
                                                setHttpRequestData({ ...httpRequestData, variables });
                                            }}
                                            sx={{ m: 1, width: '43%' }}
                                            size="small"
                                        />
                                        <Box className='round-button' sx={{ display: 'inline', verticalAlign: 'text-top' }}>
                                            <Tooltip title={t('http_request.tooltip_remove_variable')} >
                                                <IconButton
                                                    aria-label="remove-variable"
                                                    size='small'
                                                    sx={{ ml: 1.5, mt: 1 }}
                                                    onClick={(): void => removeVariable(index)}
                                                >
                                                    <Remove sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </div>
                                ))}
                            </Box>
                            <Grid container spacing={0} className='section-container'>
                                <Grid container spacing={0} sx={{ mb: 1 }}>
                                    <Grid item xs={10}>
                                        <Typography
                                            textAlign="left"
                                            variant='h6'
                                            sx={{ ml: 1, fontSize: '1.2rem' }}
                                        >
                                            {t('input.cases')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Box className='round-button' textAlign={'end'}>
                                            <Tooltip title={t('case_component.tooltip_add_case')}>
                                                <IconButton
                                                    aria-label="round-button"
                                                    onClick={addNewCase}
                                                    sx={{ ml: 5 }}
                                                >
                                                    <Add sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} justifySelf={'start'}>
                                        <Box className='expand-case-option' textAlign={'start'}>
                                            <IconButton
                                                aria-label='expand-case-item'
                                                onClick={() => { handleExpandClick(); }}
                                                sx={{ ml: 1 }}
                                            >
                                                {expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                                {!expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppableInputModalCases">
                                        {(provided: DroppableProvided) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {httpRequestData.cases.map((item, index) => (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sx={{ ml: 1, pr: 4 }}
                                                        key={`components-input-index-cases-${index}`}

                                                    >
                                                        <CaseComponent
                                                            key={index}
                                                            data={item}
                                                            index={index}
                                                            removeCase={removeCase}
                                                            saveCase={saveCase}
                                                            addVariable={addCaseVariable}
                                                            removeVariable={removeCaseVariable}
                                                            saveVariable={saveCaseVariable}
                                                            expanded={expanded}
                                                            textFileWidth={'47%'}
                                                        />
                                                    </Grid>
                                                ))}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </TabPanel>
                        <TabPanel index={1} value={tabValue}>
                            <AceEditor
                                placeholder={t('http_request.placeholder_text')}
                                mode="json"
                                theme="solarized_light"
                                name="blah2"
                                onChange={(value): void => setHttpRequestRawData(value)}
                                fontSize={16}
                                lineHeight={22}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={httpRequestRawData}
                                width={'100%'}
                                setOptions={{
                                    useWorker: false,
                                    enableBasicAutocompletion: false,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                }}
                            />
                        </TabPanel>
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('http_request.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('http_request.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};