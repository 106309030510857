import { ClassicPreset as Classic } from 'rete';
import { InputControl, InputModalControl } from '../controls';
import { DiContainer } from '../types';
import { InputData } from '../models/Input';

declare type InputNodeData = {
    node_id: string;
    height?: number;
    input_data: InputData;
};

const socket = new Classic.Socket('socket');
export class InputNode extends Classic.Node {
    width = 220;
    height = 180;
    constructor(di: DiContainer, nodeData?: InputNodeData) {
        super('Input');

        const modalControl = new InputModalControl(
            { di: di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket: socket, modalInfo: nodeData?.input_data }
        );
        this.height = nodeData?.height || 180;
        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('default', new Classic.Output(socket, 'default', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl('modal', modalControl);
    };
}