import { ClassicPreset as Classic } from 'rete';
import { InputControl } from '../controls';
import { StartSectionData } from '../models/StartSection';
import { DiContainer } from '../types';
import { StartSectionModalControl } from '../controls/';

const socket = new Classic.Socket('socket');

declare type StartSectionNodeData = {
    node_id: string;
    start_section_data?: StartSectionData;
};

export class StartSectionNode extends Classic.Node {
    width = 220;
    height = 120;
    constructor(di: DiContainer, nodeData?: StartSectionNodeData) {
        super('StartSection');

        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl({ hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id })
        );
        this.addControl(
            'modal',
            new StartSectionModalControl(
                { di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket, modalInfo: nodeData?.start_section_data }
            )
        );
    }
}