import { ClassicPreset as Classic } from 'rete';
import { InputControl } from '../controls';
import { DiContainer } from '../types';
import { LocationData } from '../models/Location';
import { LocationModalControl } from '../controls/modal/location_modal/LocationModal';

declare type LocationNodeData = {
    node_id: string;
    location_data: LocationData;
};

const socket = new Classic.Socket('socket');
export class LocationNode extends Classic.Node {
    width = 220;
    height = 200;
    constructor(di: DiContainer, nodeData?: LocationNodeData) {
        super('Location');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl('modal', new LocationModalControl({ di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket, modalInfo: nodeData?.location_data }));
    }
}