
import React, { useState, useEffect, useRef } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Button,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save } from '@mui/icons-material';
import { DiContainer, NodeID, ImportNodeData, Module } from '../../../types';
import { SubroutineData } from '../../../models/Subroutine';
import { useTranslation } from 'react-i18next';
import { slugify_text, mainParams } from '../../../../util/util';
import { getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import { StartSubModalControl } from '../start_sub_modal/StartSubModal';
import useLocalStorage from '../../../../hooks/useLocalStorage';

const [, botMxid] = mainParams();

declare type SubroutineModalControlOptions = {
    modalInfo?: SubroutineData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class SubroutineModalControl extends Classic.Control {
    constructor(public options?: SubroutineModalControlOptions) {
        super();
    }
}

export const SubroutineModal = (props: { data: SubroutineModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [subroutineData, setSubroutineData] = useState<SubroutineData>(props.data.options?.modalInfo || {
        name: getNextNodeName('Subroutine', 'subroutine', props.data.options?.di.editor!),
        go_sub: '',
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [inputSelectedModule, setInputSelectedModule] = useState('');
    const [subroutineNodes, setSubroutineNodes] = useState<JSX.Element[]>([]);
    const [open, setOpen] = useState(false);
    const [modules] = useLocalStorage<Module[]>(botMxid, []);
    const [selectedModule] = useLocalStorage(`selected_module-${botMxid}`, 'Main');

    useEffect(() => {
        const joinNodes = modules.flatMap((module: Module) => module.content);
        setInputSelectedModule(joinNodes.find((node) => node.id === subroutineData.go_sub)?.module || '');
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (props.data.options?.modalInfo && !open) {
            setSubroutineData(props.data.options.modalInfo);
        }
    }, [subroutineData, open]);

    useEffect(() => {
        if(inputSelectedModule === '') return;

        let selectOptions: JSX.Element[] = [];
        if (inputSelectedModule === selectedModule) {
            const options = props.data.options?.di.editor.getNodes().filter(node => node.label === 'StartSub');
            if (!options) return;

            selectOptions = options!.map((option, index) => {
                const optionInfo = (option.controls.modal as StartSubModalControl)?.options?.modalInfo;
                return (
                    <MenuItem key={`sub-${index}`} value={slugify_text(optionInfo?.name)}>
                        {optionInfo?.name}
                    </MenuItem>
                );
            });

        }else {
            const moduleIndex = modules.findIndex((module: Module) => module.label === inputSelectedModule);

            selectOptions = (modules[moduleIndex].content as ImportNodeData[]).filter((node) => node.type === 'message' && node.subtype === 'start_sub').map(
                (node: any, index: number) => (
                    <MenuItem key={`start-${index}`} value={node.id}>
                        {node.name}
                    </MenuItem>
                )
            );
        };
        setSubroutineNodes(selectOptions);
    }, [inputSelectedModule]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const renderModulesOptions = (): JSX.Element[] => {
        return modules
            .map((option, index) => (
                <MenuItem key={`sect-${index}`} value={option.label}>
                    {option.label}
                </MenuItem>
            ));
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        if (subroutineData.name.length > 20) {
            return t('subroutine.name_field_too_long');
        } else if (nodeNameAlreadyExists(subroutineData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('subroutine.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }

        props.data.options!.modalInfo = subroutineData;
        props.data.options!.di.updateNode(props.data.options!.nodeId.reteNodeId);
        onClose();
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('subroutine.subroutine_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('subroutine.required_fields')}
                        </Typography>
                        <TextField
                            label={t('subroutine.name')}
                            id='node-name'
                            value={subroutineData.name}
                            required
                            onChange={(e): void => setSubroutineData({ ...subroutineData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, width: '47%' }} size="small">
                            <InputLabel id='module-label'>{t('subroutine.modules')}</InputLabel>
                            <Select
                                labelId='module-label'
                                id='modules-select'
                                value={inputSelectedModule}
                                required
                                onChange={(e): void => setInputSelectedModule(e.target.value)}
                                label={t('subroutine.modules')}
                                autoFocus
                            >
                                {renderModulesOptions()}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, width: '47%' }} size="small">
                            <InputLabel id='node-go-sub-label'>{t('subroutine.go_sub')}</InputLabel>
                            <Select
                                labelId='node-go-sub-label'
                                id='go-sub-select'
                                value={subroutineData.go_sub}
                                required
                                onChange={(e): void => setSubroutineData({ ...subroutineData, go_sub: e.target.value })}
                                label={t('invite_user.destination')}
                            >
                                {subroutineNodes}
                            </Select>
                        </FormControl>
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('subroutine.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('subroutine.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};