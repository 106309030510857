import { Flow } from '../rete/models/Flow';
import config from '../config.json';
import { BackupFlow, BackupContent } from '../rete/types';
import { formatDate } from '../util/util';
export class FlowService {
    base_url: string;
    bot_mxid: string;

    constructor(base_url: string, bot_mxid: string) {
        this.base_url = config.menuflow.base_url.replace('<domain>', base_url);
        this.bot_mxid = bot_mxid;
    }

    getFlow = async (): Promise<[{ id: number, flow: Flow }, number]> => {
        const url = `${this.base_url}/v1/flow?client_mxid=${this.bot_mxid}`;
        try {
            const response = await fetch(`${url}`, {
                method: 'get',
            });
            const json: { id: number, flow: Flow } = await response.json();
            const status: number = response.status;
            return [json, status];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    updateFlow = async (flow_id: number, flow: Flow): Promise<[{ error: string }, number]> => {
        const url = `${this.base_url}/v1/flow`;
        try {
            const response = await fetch(`${url}`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 'id': flow_id, 'flow': flow }),
            });
            const json: { error: string } = await response.json();
            const status: number = response.status;
            return [json, status];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    updateClientFlow = async (flow_id: number): Promise<[{ error: string }, number]> => {
        const url = `${this.base_url}/v1/client/${this.bot_mxid}/flow`;
        try {
            const response = await fetch(`${url}`, {
                method: 'patch',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 'flow_id': flow_id }),
            });
            const json: { error: string } = await response.json();
            const status: number = response.status;
            return [json, status];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    getBackupFlows = async (flow_id: number, limit: number, offset: number, backup_id?: number): Promise<[BackupFlow | BackupContent, number]> => {
        let url = `${this.base_url}/v1/flow/${flow_id}/backup?limit=${limit}&offset=${offset}`;
        if (backup_id) {
            url += `&backup_id=${backup_id}`;
        }
        try {
            const response = await fetch(`${url}`, {
                method: 'get',
            });
            const result = await response.json();

            if (backup_id) {
                return [result, response.status];
            }

            const backups: BackupContent[] = result.backups.map((backup: BackupContent) => {
                return {
                    id: backup.id,
                    flow_id: backup.flow_id,
                    created_at: formatDate(new Date(backup.created_at+' UTC')),
                };
            });

            const json: { flows: BackupContent[], count: number } = {
                flows: backups,
                count: result.count,
            };
            const status: number = response.status;
            return [json, status];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
}