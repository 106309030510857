import { ClassicPreset as Classic } from 'rete';
import { InputControl, MessageModalControl } from '../controls';
import { DiContainer } from '../types';
import { MessageData } from '../models/Message';

const socket = new Classic.Socket('socket');

declare type MessageNodeData = {
    node_id: string;
    message_type: string;
    nodeData?: MessageData;
};

export class MessageNode extends Classic.Node {
    width = 220;
    height = 160;
    constructor(di: DiContainer, nodeData?: MessageNodeData) {
        super('Message');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl(
            'modal',
            new MessageModalControl(
                { di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket, modalInfo: nodeData?.nodeData }
            )
        );
    }
}