
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Grid,
    IconButton,
    Button,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { Add, Remove, WarningAmber, Settings, Cancel, Save } from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import { SetVarsData } from '../../../models/SetVars';
import { useTranslation } from 'react-i18next';
import { getNextNodeName, nodeNameAlreadyExists } from '../../../../util';

declare type SetVarsModalControlOptions = {
    modalInfo?: SetVarsData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class SetVarsModalControl extends Classic.Control {
    constructor(public options?: SetVarsModalControlOptions) {
        super();
    }
}

export const SetVarsModal = (props: { data: SetVarsModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [setVarsData, setSetVarsData] = useState<SetVarsData>({
        name: getNextNodeName('Set Vars', 'set_vars', props.data.options?.di.editor!),
        set: [{ 'name': '', 'value': '' }],
        unset: [''],
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useMemo(() => {
        if (props.data.options?.modalInfo) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            setSetVarsData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        if (setVarsData.set.length === 0 && setVarsData.unset.length === 0) {
            return t('set_vars.at_least_one_set_variable_or_unset_variable_is_required');
        }else if (setVarsData.name.length > 20) {
            return t('set_vars.name_field_too_long');
        } else if (nodeNameAlreadyExists(setVarsData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('set_vars.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const errorMessage = validateForm();
        if (errorMessage) {
            showErrorMessage(errorMessage);
            return;
        };

        props.data.options!.modalInfo = setVarsData;
        props.data.options?.di.updateNode(props.data.options.nodeId.reteNodeId);
        onClose();
    };

    const addSetVariable = (): void => {
        const newSetVarsData = { ...setVarsData };
        newSetVarsData.set.push({ 'name': '', 'value': '' });
        setSetVarsData(newSetVarsData);
    };

    const removeSetVariable = (index: number): void => {
        const newSetVarsData = { ...setVarsData };
        newSetVarsData.set.splice(index, 1);
        setSetVarsData(newSetVarsData);
    };

    const addUnsetVariable = (): void => {
        const newSetVarsData = { ...setVarsData };
        newSetVarsData.unset.push('');
        setSetVarsData(newSetVarsData);
    };

    const removeUnsetVariable = (index: number): void => {
        const newSetVarsData = { ...setVarsData };
        newSetVarsData.unset.splice(index, 1);
        setSetVarsData(newSetVarsData);
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('set_vars.set_vars_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <TextField
                            label={t('set_vars.name')}
                            id='node-name'
                            value={setVarsData.name}
                            required
                            onChange={(e): void => setSetVarsData({ ...setVarsData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <Typography textAlign='left' variant='subtitle1'>{t('set_vars.set_variables')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='box-button-style'>
                                    <IconButton aria-label='add-set-variable' onClick={addSetVariable}><Add sx={{ fontSize: '1rem' }}/></IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        {setVarsData.set.map((item, index) => (
                            <Box key={index} className='set_vars_content' sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    label={t('set_vars.variable_name')}
                                    id={`set-variable-name-${index}`}
                                    value={item.name}
                                    onChange={(e): void => {
                                        const newSetVarsData = { ...setVarsData };
                                        newSetVarsData.set[index].name = e.target.value;
                                        setSetVarsData(newSetVarsData);
                                    }}
                                    required
                                    sx={{ m: 1, width: '48.5%' }}
                                    size="small"
                                />
                                <TextField
                                    label={t('set_vars.value')}
                                    id={`set-variable-value-${index}`}
                                    value={item.value}
                                    onChange={(e): void => {
                                        const newSetVarsData = { ...setVarsData };
                                        newSetVarsData.set[index].value = e.target.value;
                                        setSetVarsData(newSetVarsData);
                                    }}
                                    multiline
                                    sx={{ m: 1, width: '48.5%' }}
                                    size="small"
                                />
                                <Box className='box-button-style' sx={{ display: 'inline'}} >
                                    <IconButton aria-label='remove-set-variable' sx={{ m: 1 }} onClick={(): void => removeSetVariable(index)}>
                                        <Remove sx={{ fontSize: '1rem' }}/>
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                        <Grid container spacing={2} className='mt-16'>
                            <Grid item xs={11}>
                                <Typography textAlign='left' variant='subtitle1'>{t('set_vars.unset_variables')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='float-right box-button-style'>
                                    <IconButton aria-label='add-unset-variables' onClick={addUnsetVariable}><Add sx={{ fontSize: '1rem' }}/></IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        {setVarsData.unset.map((item, index) => (
                            <Box key={index} className='set_vars_content' sx={{ display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    label={t('set_vars.variable_name')}
                                    id={`unset-variable-id-${index}`}
                                    value={item}
                                    onChange={(e): void => {
                                        const newSetVarsData = { ...setVarsData };
                                        newSetVarsData.unset[index] = e.target.value;
                                        setSetVarsData(newSetVarsData);
                                    }}
                                    sx={{ m: 1, width: '98.5%' }}
                                    size="small"
                                />
                                <Box className='box-button-style' sx={{ display: 'inline'}} >
                                    <IconButton aria-label='remove-unset-variable' sx={{ m: 1 }} onClick={(): void => removeUnsetVariable(index)}>
                                        <Remove sx={{ fontSize: '1rem' }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('set_vars.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('set_vars.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};