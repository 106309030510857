import { ClassicPreset as Classic } from 'rete';
import { InputControl, SwitchModalControl } from '../controls';
import { DiContainer } from '../types';
import { SwitchData } from '../models/Switch';

declare type SwitchNodeData = {
    node_id: string;
    height?: number;
    switch_node_data: SwitchData;
};

const socket = new Classic.Socket('socket');
export class SwitchNode extends Classic.Node {
    width = 220;
    height = 180;
    constructor(di: DiContainer, nodeData?: SwitchNodeData) {
        super('Switch');

        this.height = nodeData?.height || 180;
        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('default', new Classic.Output(socket, 'default', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl(
            'modal',
            new SwitchModalControl(
                { di: di, nodeId: { reteNodeId: this.id, menuflowNodeId: nodeData?.node_id || '' }, socket: socket, modalInfo: nodeData?.switch_node_data }
            )
        );

    }
}